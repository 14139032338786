import { mergeDuplicatedConversations } from '~/pages/CustomerServiceChat/functions'
import {
  CLEAR_CONVERSATIONS,
  SET_CONVERSATIONS,
  CLEAR_CONVERSATIONS_STATE,
  APPLY_CONVERSATIONS_CHANGES,
} from '../actions/conversationAction'

const initialState = {
  conversations: [],
}

const conversationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONVERSATIONS: {
      const merged = mergeDuplicatedConversations({
        conversations: action.data,
      })

      return {
        ...state,
        conversations: merged.sort((a, b) => b.timestamp - a.timestamp),
      }
    }
    case CLEAR_CONVERSATIONS:
      return {
        ...state,
        conversations: [],
      }
    case APPLY_CONVERSATIONS_CHANGES: {
      const chatsToAdd = action.data.filter(x => !x.deleted)

      const chatsToDelete = action.data.filter(x => x.deleted)

      const newChats = []

      state.conversations.forEach(element => {
        let finded = false

        if (chatsToAdd.find(x => x.doc.phoneNumber === element.phoneNumber)) {
          finded = true
        }

        if (chatsToDelete.find(x => x.id === element._id)) {
          finded = true
        }

        if (!finded) {
          newChats.push(element)
        }
      })

      const merged = mergeDuplicatedConversations({
        conversations: [...newChats, ...chatsToAdd.map(x => x.doc)],
      })

      return {
        ...state,
        conversations: merged.sort((a, b) => b.timestamp - a.timestamp),
      }
    }
    case CLEAR_CONVERSATIONS_STATE: {
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
}

export default conversationReducer
