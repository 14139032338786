import { useState, useEffect } from 'react'
import { Form } from '@unform/web'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  requestingLogin,
  loginSuccess,
  loginFailed,
  loading,
} from '~/store/root/slices/auth'
import { axiosAuth } from '~/services/api'
import Input from '~/components/InputPassword'
import { Col, Row } from 'reactstrap'
import { toast } from 'react-toastify'
import { Spinner } from 'reactstrap'
import { Options, TLink } from './styles'
import { Container, Content, Button } from '~/styles/password/styles'

import Wallpaper from '~/components/imgs/bg_web.jpg'

import Logo from '~/components/imgs/logotripmee_parafundobranco.png'
import { setContacts } from '~/store/actions/contactAction'
import { setConversations } from '~/store/actions/conversationAction'

export default function SignIn() {
  const dispatch = useDispatch()
  const { t } = useTranslation('SignIn')
  const auth = useSelector(state => state.auth)
  const [email, setEmail] = useState('')
  const [password, setPass] = useState('')
  const [hidePass, setHidePass] = useState(true)
  const [loadingLogin, setLoadingLogin] = useState(false)

  useEffect(() => {
    dispatch(loading(false))
    console.log('SignIn auth', auth)
  }, [])

  async function handleSubmit() {
    try {
      setLoadingLogin(true)

      var { data } = await axiosAuth.post('authagent', {
        email,
        password,
      })

      dispatch(requestingLogin())

      resetChatcenterData()

      dispatch(
        loginSuccess({
          accessToken: data.accessToken,
          user: data.payload.user,
          tenant: data.payload.dJGDkPU4,
        }),
      )

      localStorage.setItem('Q937p@qC', true)

      setLoadingLogin(false)
    } catch (error) {
      setLoadingLogin(false)

      dispatch(loginFailed())

      return toast.info('Login ou senha incorretos.')
    }
  }

  const resetChatcenterData = () => {
    setConversations([], dispatch)
    setContacts([], dispatch)
    setConversations([], dispatch)
  }

  return (
    <Container>
      <img
        src={Wallpaper}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: '20%',
          position: 'absolute',
        }}
      />
      <Content
        bg={'rgba(127, 46, 194)'}
        maxWidth={'540px'}
        padding={'24px 48px'}
      >
        <img
          src={Logo}
          style={{
            maxWidth: '200px',
            marginBottom: '32px',
          }}
        />

        <Form
          onSubmit={handleSubmit}
          initialData={{
            email: email,
            password: password,
          }}
        >
          <Row>
            <Col sm={12}>
              <Input
                name='email'
                type='email'
                maxLength={50}
                title='Informe um endereço de e-mail válido.'
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder={t('Form.Inputs.Email')}
                style={{ borderRadius: 0 }}
                required
                disabled={loadingLogin}
              />

              <Input
                name='password'
                type={hidePass ? 'password' : 'text'}
                value={password}
                onChange={e => setPass(e.target.value)}
                placeholder={t('Form.Inputs.Password')}
                style={{ borderRadius: 0 }}
                isPasswordForLogin={true}
                hidePass={hidePass}
                callbackHidePassword={() => setHidePass(!hidePass)}
                required
                disabled={loadingLogin}
              />

              {/* <div className"g-recaptcha">
                    <Recaptcha
                      sitekey='6LdtM00bAAAAAL6fuwkePazuMBJZJID6kCfHYRY8'
                      render='explicit'
                      size='normal'
                      verifyCallback={verifyCallback}
                      onloadCallback={callback}
                      data-callback='onSubmit'
                      hl='pt-BR'
                      theme='light'
                    />
                  </div> */}

              <Button
                data-cy='login-button'
                type='submit'
                disabled={loadingLogin}
              >
                {!loadingLogin && t('ButtonSubmit')}

                {loadingLogin && <Spinner color={'#eee'} size={'sm'} />}
              </Button>
            </Col>
          </Row>
        </Form>
        <Options>
          <TLink to='/forgot-password'>ESQUECEU SUA SENHA?</TLink>
        </Options>
        <p style={{ fontSize: 13, color: '#afa9b5', fontWeight: 'bold' }}>
          Todos direitos reservados {new Date().getFullYear()} &copy; TripMee
        </p>
      </Content>
    </Container>
  )
}
