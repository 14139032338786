import InfinityScroll from '~/components/InfinityScroll'
import ContactConversationSkeleton from './ContactConversation/skeleton'

export default function ContactListSkeleton() {
  return (
    <InfinityScroll
      data={[0, 1, 2, 3, 4]}
      disableScroll={true}
      renderComponent={e => {
        return <ContactConversationSkeleton key={e} />
      }}
    />
  )
}
