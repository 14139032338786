import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import HoldingContext from '~/config/HoldingContext'
import { signOut } from '~/store/root/slices/auth'
import {
  ButtonMenu,
  Container,
  LinkHome,
  LinkHomeBadge,
  LinkMenu,
  SpanNavbarToggle,
} from './styles'
import { phoneNumber } from '~/utils/supportPhone'
import { clearContactState } from '~/store/actions/contactAction'
import { clearConversationsState } from '~/store/actions/conversationAction'
import { clearServiceState } from '~/store/actions/serviceAction'
import { clearCustomerState } from '~/store/actions/customerAction'
import { clearAgentState } from '~/store/actions/agentAction'

export default function HoldingHeader() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const user = useSelector(state => state.auth.user)

  async function handleLogout() {
    clearContactState(dispatch)
    clearConversationsState(dispatch)
    clearServiceState(dispatch)
    clearCustomerState(dispatch)
    clearAgentState(dispatch)

    dispatch(signOut())

    navigate('/')
  }

  return (
    <HoldingContext.Consumer>
      {({ toggleMobileSidebar, pageSidebarToggled, pageSidebar }) => (
        <Container id='header' className='header navbar-inverse'>
          <div className='header-content'>
            <div className='navbar-header'>
              {pageSidebar && (
                <button
                  type='button'
                  className='navbar-toggle'
                  onClick={toggleMobileSidebar}
                >
                  <SpanNavbarToggle className='icon-bar' />

                  <SpanNavbarToggle className='icon-bar' />

                  <SpanNavbarToggle className='icon-bar' />
                </button>
              )}

              <LinkHome to='/' className='navbar-header-a'>
                <span>{user.agencyName}</span>

                <LinkHomeBadge>HOLDING</LinkHomeBadge>
              </LinkHome>
            </div>

            <ul className='navbar-nav navbar-right'>
              {/* <li>
                <LinkMenu
                  to={'/agency'}
                  className={`${
                    '/agency' === location.pathname ? 'active' : ''
                  }`}
                >
                  MINHA HOLDING
                </LinkMenu>
              </li>

              <li>
                <LinkMenu
                  to={'/profile'}
                  className={`${
                    '/profile' === location.pathname ? 'active' : ''
                  }`}
                >
                  MEU PERFIL
                </LinkMenu>
              </li> */}

              <li>
                <LinkMenu
                  to={'/about'}
                  onClick={pageSidebarToggled ? toggleMobileSidebar : undefined}
                  className={`${
                    '/about' === location.pathname ? 'active' : ''
                  }`}
                >
                  SOBRE
                </LinkMenu>
              </li>

              <li>
                <ButtonMenu
                  target='_blank'
                  rel='noopener noreferrer'
                  type='button'
                  onClick={() =>
                    window.open(
                      `https://api.whatsapp.com/send?phone=${phoneNumber(
                        'support',
                      )}`,
                    )
                  }
                >
                  AJUDA
                </ButtonMenu>
              </li>

              <li>
                <ButtonMenu onClick={handleLogout}>SAIR</ButtonMenu>
              </li>
            </ul>
          </div>
        </Container>
      )}
    </HoldingContext.Consumer>
  )
}
