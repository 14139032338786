import styled from 'styled-components'
import { Dropdown } from 'primereact/dropdown'
import { Modal, ModalBody } from 'reactstrap'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  border-right: 1px solid #ccc;
`

export const IconBottom = styled.i`
  color: #caccce;
  padding: 0px 6px;
  font-size: 20px;
`

export const Keyboard = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const InputTextArea = styled.textarea`
  height: 76px;
  margin: 6px 10px 10px 10px;
  resize: none;
  border-bottom: 1px solid #caccce;
  border: none;
  :focus {
    outline: none !important;
    border: none;
  }
`

export const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 4px 10px;
  border-top: 1px solid #caccce;
`

export const Features = styled.div`
  display: flex;
  gap: 8px;
`

export const MessagesContainer = styled.div`
  width: 100%;
  flex: 1;
  padding: 4px;
  border-bottom: 1px solid #ccc;
  word-break: break-all;
  overflow: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  background: #eaeaea;
`

export const ContactText = styled.p`
  background: #e9e9e9;
  border-radius: 10px;
  padding: 10px;
`

export const LabelDocumentClip = styled.label`
  cursor: pointer;
`

export const ReplyMessageSection = styled.div`
  width: 100%;
  padding: 8px;
  background-color: #eaeaea;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
`

export const ReplyMessageSectionName = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const ReplyMessageSectionWithinMessage = styled.div`
  border-radius: 3px;
  border: 1px solid #cecece;
  border-left: 4px solid #77c4d5;
  border-top: 2px solid #cfcfcf;
  margin-bottom: 5px;
  padding: 5px;
  background-color: #efefef85;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;

  span {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    max-height: 1.4em;
    line-height: 1.4em;
  }
`

export const Select = styled(Dropdown)`
  height: 0;
  width: 0;
  border: none;

  :active {
    border: none;
  }

  .p-dropdown-trigger {
    height: 0;
    width: 0;
  }
`

export const ImageModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 100vw;
  min-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;

  @media (min-width: 576px) {
    width: 100vw;
    max-width: 100vw;
    min-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    min-height: 100vh;
    margin: 0;
  }

  .modal-content {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    background-color: transparent !important;
    background-clip: padding-box;
    border: none !important;
    border-radius: 0;
    outline: 0;
  }
`

export const ImageModalBody = styled(ModalBody)`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  i {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
  }

  img {
    max-width: 90%;
    max-height: 90%;
  }
`
