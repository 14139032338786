import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import PageSettings from '~/config/PageSettings'
import usePermissions from '~/hooks/usePermissions'
import { signOut } from '~/store/root/slices/auth'
import {
  ButtonMenuDropdown,
  Container,
  LinkHome,
  LinkHomeBadge,
  LinkMenu,
  SpanNavbarToggle,
  AvatarSoft,
  ItemMenuDropdown,
} from './styles'
import { phoneNumber } from '~/utils/supportPhone'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap'
import { TextEmail, TextUser } from '../Sidebar/Profile/styles'
import { GetAxios } from '~/services/api'
import context from '~/context/createContext'
import { onClickHeaderNavItem } from '~/store/root/slices/page'
import { clearContactState } from '~/store/actions/contactAction'
import { clearConversationsState } from '~/store/actions/conversationAction'
import { getInstanceStatus } from '~/services/chat'
import { clearServiceState } from '~/store/actions/serviceAction'
import { clearCustomerState } from '~/store/actions/customerAction'
import { clearAgentState } from '~/store/actions/agentAction'

function Header() {
  const dispatch = useDispatch()
  const { axiosApis } = GetAxios()
  const [travelCount, setTravelCount] = useState(0)
  const [experiencesCount, setExperiencesCount] = useState(0)
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useSelector(state => state.auth)
  const user = auth.user
  const { isMaster, isAdmin, isAgent, role } = usePermissions()
  const [roleName, setRoleName] = useState('agente')
  const supportPhone = phoneNumber('support')
  const whatsappLink = `https://api.whatsapp.com/send?phone=${supportPhone}/`
  const { setHandleProfile, agency } = useContext(context)
  const { headerNavItemSelected } = useSelector(state => state.page)
  const [showConnectWhatsapp, setShowConnectWhatsapp] = useState(false)

  useEffect(() => {
    switch (user.role) {
      case 'admin':
        setRoleName('agência')
        break
      case 'agent':
        setRoleName('agente')
        break
      case 'library':
        setRoleName('biblioteca')
        break
      default:
        setRoleName('agente')
        break
    }

    if (user.isOperator) setRoleName('operadora')
  }, [])

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        const { data: dataTravels } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agency/travel/count`,
        )

        setTravelCount(dataTravels.count)

        const { data: dataExperiences } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agency/experience/count`,
        )

        setExperiencesCount(dataExperiences.count)
      } catch (error) {
        setTravelCount(0)
        setExperiencesCount(0)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  useEffect(() => {
    async function getSource() {
      try {
        if (auth.tenant && agency?.enableChatCenter) {
          const status = await getInstanceStatus(auth.tenant)

          if (status.connected === true) {
            setShowConnectWhatsapp(true)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }

    getSource()

    return () => {}
  }, [agency])

  async function handleLogout() {
    const element = document.getElementById('beamerSelector')

    if (element) element.remove()

    clearContactState(dispatch)
    clearConversationsState(dispatch)
    clearServiceState(dispatch)
    clearCustomerState(dispatch)
    clearAgentState(dispatch)

    dispatch(signOut())

    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }

  const redirect = route => {
    navigate(route)
  }

  const openHelpLink = () => {
    window.open(whatsappLink)
  }

  const onClickMenu = (key, callback) => {
    if (key) {
      dispatch(onClickHeaderNavItem(key))
    }

    if (callback) {
      callback()
    }
  }

  return (
    <PageSettings.Consumer>
      {({ toggleMobileSidebar, pageSidebar }) => (
        <Container id='header' className='header navbar-inverse'>
          <div className='header-content'>
            <div style={{ overflow: 'visible' }} className='navbar-header'>
              {pageSidebar && (
                <button
                  type='button'
                  className='navbar-toggle'
                  onClick={toggleMobileSidebar}
                >
                  <SpanNavbarToggle className='icon-bar' />

                  <SpanNavbarToggle className='icon-bar' />

                  <SpanNavbarToggle className='icon-bar' />
                </button>
              )}

              <LinkHome to='/' className='navbar-header-a'>
                <span>{isMaster ? 'MASTER' : user.agencyName}</span>

                {!isMaster && <LinkHomeBadge>{roleName}</LinkHomeBadge>}
              </LinkHome>
            </div>

            <div>
              <ul
                className='navbar-nav navbar-right'
                style={{ overflow: 'visible' }}
              >
                {user.role == 'admin' && (
                  <li
                    onClick={() =>
                      onClickMenu('agency', () => {
                        if (setHandleProfile) {
                          setHandleProfile(false)
                        }
                      })
                    }
                  >
                    <LinkMenu
                      to={'/schedule'}
                      className={
                        headerNavItemSelected === 'agency' ? 'active' : ''
                      }
                    >
                      <i className='fas fa-home'></i>
                      &nbsp;
                      <span>AGÊNCIA</span>
                    </LinkMenu>
                  </li>
                )}

                {user.role == 'admin' && (
                  <li
                    onClick={() =>
                      onClickMenu('indicators', () => {
                        if (setHandleProfile) {
                          setHandleProfile(true)
                        }
                      })
                    }
                  >
                    <LinkMenu
                      to={'/home'}
                      className={
                        headerNavItemSelected === 'indicators' ? 'active' : ''
                      }
                    >
                      <i className='fas fa-chart-line'></i>
                      &nbsp; INDICADORES
                    </LinkMenu>
                  </li>
                )}

                {showConnectWhatsapp && (
                  <li
                    onClick={() => {
                      if (setHandleProfile) {
                        setHandleProfile(false)
                      }

                      onClickMenu('chat')
                    }}
                  >
                    <LinkMenu
                      to={'/chat'}
                      className={
                        headerNavItemSelected === 'chat' ? 'active' : ''
                      }
                    >
                      <i className='far fa-comment-dots' />
                      &nbsp; ATENDIMENTO
                    </LinkMenu>
                  </li>
                )}

                {isAdmin == true && user.isOperator && (
                  <li
                    onClick={() => {
                      if (setHandleProfile) {
                        setHandleProfile(false)
                      }

                      onClickMenu('my-operator')
                    }}
                  >
                    <LinkMenu
                      to={'/agency'}
                      className={
                        headerNavItemSelected === 'my-operator' ? 'active' : ''
                      }
                    >
                      <>
                        <i className='fas fa-users-cog' />
                        &nbsp; MINHA OPERADORA
                      </>
                    </LinkMenu>
                  </li>
                )}

                {isAdmin == true && !user.isOperator && (
                  <li
                    onClick={() => {
                      if (setHandleProfile) {
                        setHandleProfile(false)
                      }

                      onClickMenu('my-agency')
                    }}
                  >
                    <LinkMenu
                      to={'/agency'}
                      className={
                        headerNavItemSelected === 'my-agency' ? 'active' : ''
                      }
                    >
                      <>
                        <i className='fas fa-users-cog' />
                        &nbsp; MINHA AGÊNCIA
                      </>
                    </LinkMenu>
                  </li>
                )}

                <li
                  onClick={() => {
                    if (setHandleProfile) {
                      setHandleProfile(false)
                    }

                    onClickMenu('reminders')
                  }}
                >
                  <LinkMenu
                    to={'/reminders'}
                    className={
                      headerNavItemSelected === 'reminders' ? 'active' : ''
                    }
                  >
                    <i className='fas fa-tasks'></i>
                    &nbsp; TAREFAS
                  </LinkMenu>
                </li>

                <li onClick={() => onClickMenu(undefined, openHelpLink)}>
                  <LinkMenu to='#' target={'_blank'}>
                    <i className='fas fa-question'></i>
                    &nbsp; AJUDA
                  </LinkMenu>
                </li>

                <li>
                  <UncontrolledDropdown style={{ display: 'flex' }}>
                    <DropdownToggle
                      style={{ display: 'flex', alignItems: 'center' }}
                      color='trasparent'
                    >
                      <AvatarSoft
                        style={{
                          backgroundSize: 'cover',
                          backgroundImage: `url('data:image/*;base64,${user.avatar}')`,
                        }}
                      />
                    </DropdownToggle>

                    <DropdownMenu style={{ marginTop: '10px' }}>
                      <DropdownItem text>
                        <TextUser>{user.fullName}</TextUser>
                      </DropdownItem>

                      <DropdownItem text>
                        <TextEmail>{user.email}</TextEmail>
                      </DropdownItem>

                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {experiencesCount > 0 && (
                          <b>
                            {experiencesCount} experiência
                            {`${experiencesCount == 1 ? '' : 's'}`}{' '}
                          </b>
                        )}

                        {travelCount > 0 && (
                          <b>
                            {travelCount} viage
                            {`${travelCount == 1 ? 'm' : 'ns'}`}{' '}
                          </b>
                        )}
                      </div>
                      <DropdownItem divider />

                      {(isAgent == true ||
                        isAdmin == true ||
                        role == 'library') && (
                        <DropdownItem onClick={() => redirect('/profile')}>
                          <ItemMenuDropdown>Meu Perfil</ItemMenuDropdown>
                        </DropdownItem>
                      )}

                      <DropdownItem
                        onClick={() =>
                          window.open(
                            'https://tripmee.com.br/lgpd.html#politica-privacidade',
                          )
                        }
                      >
                        <ItemMenuDropdown>Termos de Serviço</ItemMenuDropdown>
                      </DropdownItem>

                      <DropdownItem
                        className={`${
                          '/about' === location.pathname ? 'active' : ''
                        }`}
                        onClick={() => redirect('/about')}
                      >
                        <ItemMenuDropdown>Sobre</ItemMenuDropdown>
                      </DropdownItem>

                      <DropdownItem onClick={handleLogout}>
                        <ButtonMenuDropdown>Sair</ButtonMenuDropdown>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      )}
    </PageSettings.Consumer>
  )
}

export default Header
