import DefaultMessageSkeleton from '../Messages/DefaultMessage/skeleton'

export default function ChatConversationSkeleton() {
  return (
    <>
      {[0, 1, 2, 3, 4].map(c => {
        return <DefaultMessageSkeleton key={c} data={c} />
      })}
    </>
  )
}
