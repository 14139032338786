import SkeletonLine from '~/components/Skeleton'
import * as S from './styles'

export default function ContactsFiltersSkeleton() {
  return (
    <S.Container>
      <S.Buttons>
        <SkeletonLine width={35} height={35} />

        <SkeletonLine width={220} height={35} />

        <SkeletonLine width={35} height={35} />
      </S.Buttons>
    </S.Container>
  )
}
