import { useField } from '@unform/core'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import ReactSelect from 'react-select'
import { FormGroupDiv, Label, SubLabel } from './styles'

export default function Select({
  name,
  label,
  sublabel,
  isLoading,
  options,
  onChange,
  placeholder,
  disabled,
  value,
  inline,
  compact,
  ...rest
}) {
  const inputRef = useRef()
  const [valueInput, setValueInput] = useState(null)
  const { fieldName, defaultValue, registerField } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        if (ref && ref.current) {
          return ref.current.value
        }

        return undefined
      },
    })
  }, [fieldName, registerField])

  useEffect(() => {
    setValueInput(value ?? null)
  }, [value])

  return (
    <FormGroupDiv hideInputs={!isLoading && (options?.length ?? 0) === 0}>
      <div className={`${inline || compact ? 'mb-0' : 'mb-3'}`}>
        {label && <Label>{label}</Label>}

        <ReactSelect
          defaultValue={defaultValue}
          name={name}
          ref={inputRef}
          options={options}
          onChange={e => onChange({ target: { name: name, value: e } })}
          openMenuOnClick={options?.length > 0}
          style={{ zIndex: 123, marginBottom: 0 }}
          classNamePrefix='react-select'
          isLoading={isLoading}
          isDisabled={disabled}
          placeholder={isLoading ? 'Carregando...' : placeholder ?? ''}
          value={valueInput}
          {...rest}
        />

        {sublabel && <SubLabel>{sublabel}</SubLabel>}
      </div>
    </FormGroupDiv>
  )
}

Select.defaultProps = {
  label: null,
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  sublabel: PropTypes.string,
}
