import { Rating } from 'primereact/rating'
import * as S from './styles'

export default function RatingInput({ name, label, value, onChange }) {
  return (
    <S.Container>
      {label && <S.Label>{label}</S.Label>}

      <S.Input>
        <Rating
          name={name}
          value={value}
          onChange={e => {
            if (e.target.value === value) {
              e.target.value = 0
            }

            onChange(e)
          }}
          cancel={false}
        />
      </S.Input>
    </S.Container>
  )
}
