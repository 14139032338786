import * as S from './styles'
import SkeletonLine from '~/components/Skeleton'

export default function DefaultMessageSkeleton({ data }) {
  return (
    <S.DefaultMessageContainer toMe={data && data % 2 === 0}>
      <S.DefaultMessageContent toMe={data && data % 2 === 0} type={'text'}>
        <SkeletonLine width={128} height={19} />

        <SkeletonLine width={64} height={19} />

        <SkeletonLine width={32} height={19} />
      </S.DefaultMessageContent>
    </S.DefaultMessageContainer>
  )
}
