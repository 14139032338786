import * as S from './styles'

export default function PreviewImages({ files }) {
  return (
    <>
      <div
        style={{
          width: '100%',
          height: '500px',
          display: 'flex',
          flexWrap: 'nowrap',
        }}
      >
        {files?.length === 1 && (
          <S.MainImageContainer>
            <S.MainImage
              oneOnly
              key={files[0]?.file.id}
              src={`data:image/*;base64,${files[0].file}`}
              alt='Imagem do aéreo'
            />
          </S.MainImageContainer>
        )}

        {files?.length === 2 && (
          <>
            <S.MainImageContainer>
              <S.MainImage
                key={files[0]?.file.id}
                src={`data:image/*;base64,${files[0].file}`}
                alt='Imagem do aéreo'
              />
            </S.MainImageContainer>
            <S.MainImageContainer>
              <S.MainImage
                key={files[0]?.file.id}
                src={`data:image/*;base64,${files[1].file}`}
                alt='Imagem do aéreo'
              />
            </S.MainImageContainer>
          </>
        )}

        {files?.length === 3 && (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
            }}
          >
            <img
              style={{
                minWidth: '50%',
                maxWidth: '100%',
                height: '100%',
                objectFit: 'cover',
                padding: '0 4px 0 0',
              }}
              key={files[0]?.file.id}
              src={`data:image/*;base64,${files[0].file}`}
              alt='Imagem do aéreo'
            />
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <img
                style={{
                  width: '100%',
                  height: '50%',
                  objectFit: 'cover',
                  padding: '0 0 4px 4px',
                }}
                key={files[1]?.file.id}
                src={`data:image/*;base64,${files[1].file}`}
                alt='Imagem do aéreo'
              />
              <img
                style={{
                  width: '100%',
                  height: '50%',
                  objectFit: 'cover',
                  padding: '4px 0 0 4px',
                }}
                key={files[2]?.file.id}
                src={`data:image/*;base64,${files[2].file}`}
                alt='Imagem do aéreo'
              />
            </div>
            {/* <S.MainImageContainer>
              <S.MainImage
                key={files[0]?.file.id}
                src={`data:image/*;base64,${files[0].file}`}
                alt='Imagem do aéreo'
              />
            </S.MainImageContainer>
            <S.DoubleImageContainer>
              <S.DoubleImageContainerInsideTop>
                <S.ImageGridItem
                  key={files[1]?.file.id}
                  src={`data:image/*;base64,${files[1].file}`}
                  alt='Imagem do aéreo'
                />
              </S.DoubleImageContainerInsideTop>
              <S.DoubleImageContainerInsideBottom>
                <S.ImageGridItem
                  key={files[1]?.file.id}
                  src={`data:image/*;base64,${files[2].file}`}
                  alt='Imagem do aéreo'
                />
              </S.DoubleImageContainerInsideBottom>
            </S.DoubleImageContainer> */}
          </div>
          // <div
          //   style={{
          //     // border: '1px solid red',
          //     height: '600px',
          //     display: 'grid',
          //     gridTemplateColumns: '1fr 1fr',
          //     gridTemplateRows: '1fr 1fr',
          //   }}
          // >
          //   <img
          //     style={{
          //       padding: '0 10px 0 0',
          //       width: '100%',
          //       height: '50%',
          //       gridColumnStart: '1',
          //       gridColumnEnd: '1',
          //       gridRowStart: '1',
          //       gridRowEnd: '2',
          //     }}
          //     key={files[0]?.file.id}
          //     src={`data:image/*;base64,${files[0].file}`}
          //     alt='Imagem do aéreo'
          //   />
          //   <div
          //     style={{
          //       height: '50%',
          //       gridColumnStart: '2',
          //       gridColumnEnd: '2',
          //       gridRowStart: '1',
          //       gridRowEnd: '1',
          //     }}
          //   >
          //     <img
          //       style={{
          //         width: '100%',
          //         height: '50%',
          //         padding: '0 0 5px 0',
          //         objectFit: 'cover',
          //       }}
          //       key={files[0]?.file.id}
          //       src={`data:image/*;base64,${files[1].file}`}
          //       alt='Imagem do aéreo'
          //     />
          //     <img
          //       style={{
          //         width: '100%',
          //         height: '50%',
          //         padding: '5px 0 0 0',
          //         objectFit: 'cover',
          //       }}
          //       key={files[0]?.file.id}
          //       src={`data:image/*;base64,${files[2].file}`}
          //       alt='Imagem do aéreo'
          //     />
          //   </div>
          // </div>
        )}

        {files?.length === 4 && (
          <>
            <S.DoubleImageContainer>
              <S.QuadrupleImageContainerInsideTop>
                <S.ImageGridItem
                  key={files[1]?.file.id}
                  src={`data:image/*;base64,${files[0].file}`}
                  alt='Imagem do aéreo'
                />
              </S.QuadrupleImageContainerInsideTop>
              <S.QuadrupleImageContainerInsideBottom>
                <S.ImageGridItem
                  key={files[1]?.file.id}
                  src={`data:image/*;base64,${files[1].file}`}
                  alt='Imagem do aéreo'
                />
              </S.QuadrupleImageContainerInsideBottom>
            </S.DoubleImageContainer>
            <S.DoubleImageContainer>
              <S.QuadrupleImageContainerInsideTop right>
                <S.ImageGridItem
                  key={files[1]?.file.id}
                  src={`data:image/*;base64,${files[2].file}`}
                  alt='Imagem do aéreo'
                />
              </S.QuadrupleImageContainerInsideTop>
              <S.QuadrupleImageContainerInsideBottom right>
                <S.ImageGridItem
                  key={files[1]?.file.id}
                  src={`data:image/*;base64,${files[3].file}`}
                  alt='Imagem do aéreo'
                />
              </S.QuadrupleImageContainerInsideBottom>
            </S.DoubleImageContainer>
          </>
        )}

        {files?.length === 5 && (
          <>
            <S.MainImageContainer>
              <S.MainImage
                key={files[0]?.file.id}
                src={`data:image/*;base64,${files[0].file}`}
                alt='Imagem do aéreo'
              />
            </S.MainImageContainer>
            <div
              style={{
                flex: '1',
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <S.ImageGridItemContainer>
                <S.ImageGridItem
                  key={'1'}
                  src={`data:image/*;base64,${files[1].file}`}
                  alt='Imagem do aéreo'
                />
              </S.ImageGridItemContainer>
              <S.ImageGridItemContainer>
                <S.ImageGridItem
                  key={'2'}
                  src={`data:image/*;base64,${files[2].file}`}
                  alt='Imagem do aéreo'
                />
              </S.ImageGridItemContainer>
              <S.ImageGridItemContainer>
                <S.ImageGridItem
                  key={'3'}
                  src={`data:image/*;base64,${files[3].file}`}
                  alt='Imagem do aéreo'
                />
              </S.ImageGridItemContainer>
              <S.ImageGridItemContainer>
                <S.ImageGridItem
                  key={'4'}
                  src={`data:image/*;base64,${files[4].file}`}
                  alt='Imagem do aéreo'
                />
              </S.ImageGridItemContainer>
            </div>
          </>
        )}
      </div>
    </>
  )
}
