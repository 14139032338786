import styled from 'styled-components'

export const SectionChat = styled.div`
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
`

export const SectionInfo = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const SectionMessagesContent = styled.div`
  flex: 1;
  padding: 4px;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #caccce;
  word-break: break-all;
  overflow: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  background: #eaeaea;
`
