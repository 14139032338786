import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import IconButton from '../IconButton'

export default function InfinityScroll({
  data = [],
  renderComponent = undefined,
  bufferSize = 20,
  disableScroll,
}) {
  const listRef = useRef()
  const [list, setList] = useState([])
  const [slicePoints, setSlicePoints] = useState({
    start: 0,
    end: bufferSize,
  })

  useEffect(() => {
    setList(data.slice(slicePoints.start, slicePoints.end))
  }, [slicePoints, data])

  async function backOlderItems() {
    setSlicePoints(state => {
      let start = state.start - bufferSize
      let end = state.end

      if (end - start > 50) {
        end = end - bufferSize
      }

      return {
        start: start < 0 ? 0 : start,
        end: end,
      }
    })
  }

  async function loadMoreItems() {
    setSlicePoints(state => {
      let start = state.start
      let end = state.end + bufferSize

      if (end - start > 50) {
        start = start + bufferSize
      }

      return {
        start: start,
        end: end > data.length ? data.length : end,
      }
    })
  }

  async function backToTop() {
    listRef?.current?.scrollTo(0, 0)

    setSlicePoints({
      start: 0,
      end: bufferSize,
    })
  }

  return (
    <Container ref={listRef}>
      {!disableScroll && slicePoints.start > 0 && (
        <ButtonsContainer>
          <LoadButton onClick={backOlderItems} type={'button'}>
            Conversas mais recentes
          </LoadButton>

          <IconButton
            onClick={backToTop}
            iconOverride={'fas fa-chevron-up'}
            tooltipOverride={'Voltar para o topo'}
            style={{ padding: '8px', border: '1px solid #ccc' }}
          />
        </ButtonsContainer>
      )}

      {list.map(renderComponent)}

      {!disableScroll && (
        <ButtonsContainer>
          {slicePoints.end < data.length && (
            <LoadButton onClick={loadMoreItems} type={'button'}>
              Conversas mais antigas
            </LoadButton>
          )}

          <IconButton
            onClick={backToTop}
            iconOverride={'fas fa-chevron-up'}
            tooltipOverride={'Voltar para o topo'}
            style={{ padding: '8px', border: '1px solid #ccc' }}
          />
        </ButtonsContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  flex: 1;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  overflow: auto;
`

const LoadButton = styled.button`
  flex: 1;
  width: 100%;
  border: none;
  background: none;
  padding: 8px;
  font-weight: bold;
  border: 1px solid #ccc;
  border-radius: 8px;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px;
  border-bottom: 1px solid #ccc;
  gap: 5px;
`
