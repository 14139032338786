import * as S from './styles'
import SkeletonLine from '~/components/Skeleton'
import InformationHeaderSkeleton from './Header/skeleton'

export default function InformationSkeleton() {
  return (
    <S.Container>
      <S.ServiceButtons>
        <SkeletonLine width={64} height={35} />

        <SkeletonLine width={64} height={35} />

        <SkeletonLine width={64} height={35} />
      </S.ServiceButtons>

      <S.Content>
        <InformationHeaderSkeleton />

        <SkeletonLine width={200} height={64} />

        <SkeletonLine width={200} height={64} />
      </S.Content>
    </S.Container>
  )
}
