import { getDateFormated } from '~/functions/Date'
import {
  Container,
  Div,
  Image,
  TopTitle,
  DivEntity,
  EncapsuleBoxTitle,
  DivGlobal,
  // Button,
  AgencyImage,
  // ImportantInformation,
  AgentInformation,
  Hr,
  DivEntityAbout,
  ImageAboutContainer,
  AboutContainer,
  ImportantInformationAbout,
  AboutTitle,
  InfoBodyTd,
  ImportantInformationFooter,
  Td,
} from './styles'
import Footer from '../../Footer'
import { FormatValueBRL, FormatValueUSD } from '~/functions/FormatValue'
import DivForHTML from '~/components/DivForHTML'
// import { P } from '~/pages/ApplicationForm/Survey/Form/style'
import GaleryIcon from '~/components/imgs/galery_icon.png'
import { Table as ReactstrapTable } from 'reactstrap'
import PreviewImages from '../Images'
import { getFirstWords } from '~/functions/String'
// import { MainImage, MainImageContainer } from '../Images/styles'

export default function Preview({
  agency,
  title = '',
  image = '',
  image2 = '',
  description = '',
  primaryColor = '',
  allValues,
  dolar,
  id,
  createdAt,
  proposalFlights = [],
  agentName,
  agentEmail,
  isMobile,
  agencyPhone,
  proposalList = [],
  fullValue,
  showTotalValue,
  // proposalActivitys = [],
  // dateInitial,
  // dateFinal,
  // reservation = '',
  // proposalNauticals = [],
  // proposalTrains = [],
  // proposalTransfers = [],
  // proposalHotels = [],
  // proposalInsurances = [],
  // proposalOtherServices = [],
  // proposalDestinys = [],
  // proposalTickets = [],
  // proposalVehicleRentals = [],
  // agentPhone,
  // handlePrint,
  // handleDownload,
}) {
  // const sumOfActivityValues = () => {
  //   const sum = proposalActivitys.reduce((acc, activity) => {
  //     acc += Number(activity.value)
  //     return acc
  //   }, 0)
  //   const sumToFixed = sum

  //   const result = FormatValueBRL(sumToFixed)

  //   return result
  // }

  // const sumOfActivityValuesUSD = () => {
  //   const sum = proposalActivitys.reduce((acc, activity) => {
  //     acc += Number(activity.value)
  //     return acc
  //   }, 0)
  //   const sumToFixed = sum

  //   const result = FormatValueUSD(sumToFixed)

  //   return result
  // }

  const formatterValue = value => {
    const valueToString = String(value)

    const includesDot =
      valueToString.includes('.') || valueToString.includes(',')

    const valueFormated = includesDot
      ? `${valueToString}`
      : `${valueToString}.00`

    return valueFormated
  }

  const dataProcessing = date => {
    const data = getDateFormated(date, 'DD/MM/YYYY HH:mm')

    if (data.includes('00:00')) {
      return getDateFormated(date, 'DD/MM/YYYY')
    } else {
      return getDateFormated(date, 'DD/MM/YYYY HH:mm')
    }
  }

  const changeNullForHyphen = value => {
    const transform = value == null ? '-' : value

    return transform
  }

  const changeNullForDate = value => {
    const transform = value == null ? '-' : dataProcessing(value)

    const result = transform == '01/01/0001' ? '-' : transform

    return result
  }

  // const changeNullForValue = value => {
  //   const transform = value == null ? '-' : formatterValue(value)

  //   return transform
  // }

  console.log(proposalList)

  return (
    <div>
      <DivGlobal>
        <Container>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <AgencyImage
              src={`data:image/*;base64,${agency}`}
              alt='Logo da agência'
            />
            <div>
              <AgentInformation>N° da proposta: {id}</AgentInformation>
              <AgentInformation>Criada em: {createdAt}</AgentInformation>
              <AgentInformation>Consultor: {agentName}</AgentInformation>
              <AgentInformation>Email: {agentEmail}</AgentInformation>
              {agencyPhone && (
                <AgentInformation>Telefone: {agencyPhone}</AgentInformation>
              )}
            </div>
          </div>
          <Div>
            <TopTitle primaryColor={primaryColor}>
              <p
                style={{
                  fontFamily: 'Joanna Sans Nova Regular',
                  fontSize: '25px',
                }}
              >
                {title}
              </p>
              {/* <h6>
                Período da viagem: {dateInitial} a {dateFinal}
              </h6> */}
            </TopTitle>

            {/* <Button
              style={{
                background: '#26326B',
                borderRadius: '30px',
                padding: '4px 14px',
              }}
              type='button'
              onClick={() => handlePrint()}
            >
              Baixar pdf
              <i
                style={{ marginLeft: '6px' }}
                className='far fa-arrow-alt-circle-down'
              ></i>
            </Button> */}
          </Div>

          {image && (
            <div style={{ display: 'flex', background: '#F8F8F8' }}>
              <Image
                src={`data:image/*;base64,${image}`}
                alt='Logo da agência'
              />
            </div>
          )}

          {description != '<p><br></p>' && !image2 && (
            <DivEntityAbout style={{ background: '#F8F8F8' }}>
              <EncapsuleBoxTitle>
                <AboutTitle>
                  <img
                    src={GaleryIcon}
                    style={{
                      width: '76px',
                      height: '76px',
                      objectFit: 'cover',
                      background: '#6c6c6c',
                    }}
                  />{' '}
                  Sobre a viagem
                </AboutTitle>
              </EncapsuleBoxTitle>
              <AboutContainer>
                <ImportantInformationAbout oneImage>
                  <DivForHTML
                    text={
                      `<a style="white-space: pre-line, text-align: justify">` +
                      description +
                      `</a>`
                    }
                  />
                </ImportantInformationAbout>
              </AboutContainer>
            </DivEntityAbout>
          )}

          {description != '<p><br></p>' && image2 && (
            <DivEntityAbout style={{ background: '#F8F8F8' }}>
              <EncapsuleBoxTitle>
                <Hr style={{ flex: '1', borderTop: '1px solid #d9d9d9' }}></Hr>
                <AboutTitle>
                  <img
                    src={GaleryIcon}
                    style={{
                      width: '76px',
                      height: '76px',
                      objectFit: 'cover',
                      background: '#6c6c6c',
                    }}
                  />{' '}
                  Sobre a viagem
                </AboutTitle>
              </EncapsuleBoxTitle>
              <AboutContainer>
                <ImageAboutContainer>
                  <div style={{ display: 'flex', gap: '0.5cm' }}>
                    <Image
                      src={`data:image/*;base64,${image2}`}
                      alt='Logo da agência'
                    />
                  </div>
                </ImageAboutContainer>
                <ImportantInformationAbout>
                  <DivForHTML
                    text={
                      `<a style="white-space: pre-line, text-align: justify">` +
                      description +
                      `</a>`
                    }
                  />
                </ImportantInformationAbout>
              </AboutContainer>
            </DivEntityAbout>
          )}

          {proposalList.map((proposal, i) => (
            <DivEntity
              key={`${'proposal-' + i}`}
              backgroundNumber={i % 2 === 0 ? true : false}
              primaryColor={primaryColor}
              className='no-break'
            >
              {proposal?.name == 'proposalFlights' && (
                <>
                  {/* {proposalFlights?.some(
                    x => x.proposalFlightExcerpts.length === 0,
                  ) && (
                    <>
                      <EncapsuleBoxTitle>
                        <p>Opções de aéreo</p>
                      </EncapsuleBoxTitle>
                      {proposal.proposalFlights.map((flight, i) => (
                        <>
                          {flight?.proposalFlightExcerpts.length > 0 && (
                            <p style={{ fontSize: '16px', textAlign: 'start' }}>
                              Aéreo
                            </p>
                          )}
                          <ReactstrapTable borderless striped>
                            <tbody>
                              <tr
                                style={{
                                  fontWeight: '800',
                                }}
                              >
                                <InfoBodyTd textCenter>N°</InfoBodyTd>
                                <InfoBodyTd textCenter>PASSAGEIROS</InfoBodyTd>
                                <InfoBodyTd textCenter>
                                  DESCRIÇÃO PASSAGEIROS
                                </InfoBodyTd>
                                <InfoBodyTd textCenter>
                                  FORMA DE PAGAMENTO
                                </InfoBodyTd>
                                <InfoBodyTd textCenter>BAGAGEM</InfoBodyTd>
                                <InfoBodyTd textCenter>VALOR</InfoBodyTd>
                              </tr>
                              <tr>
                                <td>{i + 1}</td>
                                <td>
                                  {changeNullForHyphen(flight.passengers)}
                                </td>
                                <td>
                                  {changeNullForHyphen(
                                    flight.descriptionPayment,
                                  )}
                                </td>
                                <td>{flight.baggage ? 'SIM' : 'NÃO'}</td>
                                <td>{changeNullForHyphen(flight.value)}</td>
                              </tr>
                            </tbody>
                          </ReactstrapTable>
                          {flight?.proposalFlightFile?.length >= 1 && (
                            <PreviewImages files={flight?.proposalFlightFile} />
                          )}
                          <ImportantInformationFooter>
                            <DivForHTML
                              text={
                                `<b>Informações importantes do Aéreo ${
                                  i + 1
                                }: </b><a style="white-space: pre-line">` +
                                getFirstWords(
                                  flight.importantInformation,
                                  2000,
                                ) +
                                `</a>`
                              }
                            />
                          </ImportantInformationFooter>
                        </>
                      ))}
                    </>
                  )} */}
                  {proposalFlights?.some(
                    x => x.proposalFlightExcerpts?.length >= 1,
                  ) && (
                    <>
                      <EncapsuleBoxTitle>
                        <p>Opções de aéreo</p>
                      </EncapsuleBoxTitle>
                      {proposalFlights.map((flight, i) => (
                        <>
                          <div
                            key={`f-${i}`}
                            style={{
                              display: 'flex',
                              overflowX: `${isMobile ? 'scroll' : 'none'}`,
                            }}
                          >
                            <div style={{ flex: '1' }}>
                              <p
                                style={{ fontSize: '16px', textAlign: 'start' }}
                              >
                                Aéreo
                              </p>
                              <ReactstrapTable borderless striped>
                                <tbody>
                                  <tr>
                                    <InfoBodyTd>N°</InfoBodyTd>
                                    <td>{i + 1}</td>
                                  </tr>
                                  <tr>
                                    <InfoBodyTd>PASSAGEIROS</InfoBodyTd>
                                    <td>
                                      {changeNullForHyphen(flight.passengers)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <InfoBodyTd>
                                      DESCRIÇÃO PASSAGEIROS
                                    </InfoBodyTd>
                                    <td>{`${
                                      flight.passengers === 1
                                        ? flight.passengers + ' Adulto'
                                        : flight.passengers != null &&
                                          flight.passengers > 1
                                        ? flight.passengers + ' Adultos'
                                        : ''
                                    }${
                                      flight.numberOfChildren === 1
                                        ? ', ' +
                                          flight.numberOfChildren +
                                          ' Criança'
                                        : flight.numberOfChildren != null &&
                                          flight.numberOfChildren > 1
                                        ? ', ' +
                                          flight.numberOfChildren +
                                          ' Crianças'
                                        : ''
                                    }${
                                      flight.numberOfBabies === 1
                                        ? ', ' + flight.numberOfBabies + ' Bebê'
                                        : flight.numberOfBabies != null &&
                                          flight.numberOfBabies > 1
                                        ? ', ' +
                                          flight.numberOfBabies +
                                          ' Bebês'
                                        : ''
                                    }`}</td>
                                  </tr>
                                  <tr>
                                    <InfoBodyTd>FORMA DE PAGAMENTO</InfoBodyTd>
                                    <td>
                                      {changeNullForHyphen(
                                        flight.descriptionPayment,
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <InfoBodyTd>BAGAGEM</InfoBodyTd>
                                    <td>{flight.baggage ? 'Sim' : 'Não'}</td>
                                  </tr>
                                  <tr>
                                    <InfoBodyTd>VALOR</InfoBodyTd>
                                    <td>{changeNullForHyphen(flight.value)}</td>
                                  </tr>
                                </tbody>
                              </ReactstrapTable>
                            </div>
                            <div style={{ paddingLeft: '10px', flex: '2' }}>
                              <p
                                style={{ fontSize: '16px', textAlign: 'start' }}
                              >
                                Trechos do aéreo
                              </p>
                              <ReactstrapTable borderless striped>
                                <tbody>
                                  <tr
                                    style={{
                                      fontWeight: '800',
                                    }}
                                  >
                                    <InfoBodyTd textCenter>N°</InfoBodyTd>
                                    <InfoBodyTd textCenter>
                                      CIA AEREA
                                    </InfoBodyTd>
                                    <InfoBodyTd textCenter>ORIGEM</InfoBodyTd>
                                    <InfoBodyTd textCenter>DESTINO</InfoBodyTd>
                                    <InfoBodyTd textCenter>
                                      DATA SAÍDA
                                    </InfoBodyTd>
                                    <InfoBodyTd textCenter>
                                      DATA CHEGADA
                                    </InfoBodyTd>
                                  </tr>
                                  {flight.proposalFlightExcerpts?.map(
                                    (excerpt, i2) => (
                                      // eslint-disable-next-line react/jsx-key
                                      <tr>
                                        <td>{i2 + 1}</td>
                                        <td>
                                          {changeNullForHyphen(excerpt.cia)}
                                        </td>
                                        <td>
                                          {changeNullForHyphen(excerpt.origin)}
                                        </td>
                                        <td>
                                          {changeNullForHyphen(excerpt.destiny)}
                                        </td>
                                        <td>
                                          {changeNullForDate(excerpt.date)}
                                        </td>
                                        <td>
                                          {changeNullForDate(
                                            excerpt.dateArrival,
                                          )}
                                        </td>
                                      </tr>
                                    ),
                                  )}
                                </tbody>
                              </ReactstrapTable>
                            </div>
                          </div>
                          {flight?.proposalFlightFile?.length >= 1 && (
                            <PreviewImages files={flight?.proposalFlightFile} />
                          )}
                          {flight.importantInformation && (
                            <ImportantInformationFooter>
                              <DivForHTML
                                text={
                                  `<b>Informações importantes do Aéreo ${
                                    i + 1
                                  }: </b><a style="white-space: pre-line">` +
                                  getFirstWords(
                                    flight.importantInformation,
                                    2000,
                                  ) +
                                  `</a>`
                                }
                              />
                            </ImportantInformationFooter>
                          )}
                        </>
                      ))}
                    </>
                  )}
                </>
              )}

              {proposal.name == 'proposalTrains' && (
                <>
                  <EncapsuleBoxTitle>
                    <p>Trens</p>
                  </EncapsuleBoxTitle>
                  <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                    {proposal.proposalTrains.map((e, i) => (
                      <>
                        <ReactstrapTable borderless striped>
                          <tbody>
                            {i == 0 && (
                              <tr>
                                <InfoBodyTd textCenter>N°</InfoBodyTd>
                                <InfoBodyTd textCenter>CIA</InfoBodyTd>
                                <InfoBodyTd textCenter>ORIGEM</InfoBodyTd>
                                <InfoBodyTd textCenter>DESTINO</InfoBodyTd>
                                <InfoBodyTd textCenter>PARTIDA</InfoBodyTd>
                                <InfoBodyTd textCenter>CHEGADA</InfoBodyTd>
                                {allValues != false && (
                                  <InfoBodyTd textCenter>VALOR</InfoBodyTd>
                                )}
                              </tr>
                            )}
                            <tr key={i}>
                              <Td>{i + 1}</Td>
                              <Td>{changeNullForHyphen(e.cia)}</Td>
                              <Td>{changeNullForHyphen(e.origin)}</Td>
                              <Td>{changeNullForHyphen(e.destiny)}</Td>
                              <Td>{changeNullForDate(e.departure)}</Td>
                              <Td>{changeNullForDate(e.arrival)}</Td>
                              {allValues != false && dolar && (
                                <Td>{FormatValueUSD(e.value)}</Td>
                              )}
                              {allValues != false && !dolar && (
                                <Td>{FormatValueBRL(e.value)}</Td>
                              )}
                            </tr>
                          </tbody>
                        </ReactstrapTable>
                        {e?.proposalTrainFiles?.length >= 1 && (
                          <PreviewImages files={e?.proposalTrainFiles} />
                        )}
                        <ImportantInformationFooter>
                          <DivForHTML
                            text={
                              `<b>Informações importantes do náutico ${
                                i + 1
                              }: </b><a style="white-space: pre-line">` +
                              getFirstWords(e.importantInformation, 2000) +
                              `</a>`
                            }
                          />
                        </ImportantInformationFooter>
                      </>
                    ))}
                  </div>
                </>
              )}

              {proposal.name == 'proposalHotels' && (
                <>
                  <EncapsuleBoxTitle>
                    <p>Hospedagem</p>
                  </EncapsuleBoxTitle>
                  <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                    {proposal.proposalHotels.map((e, i) => (
                      <>
                        <ReactstrapTable borderless striped>
                          <tbody>
                            {i == 0 && (
                              <tr
                                styles={{
                                  backgroundColor: primaryColor,
                                  // textAlign: 'center',
                                }}
                              >
                                <InfoBodyTd textCenter>N°</InfoBodyTd>
                                <InfoBodyTd textCenter>NOME</InfoBodyTd>
                                <InfoBodyTd textCenter>ENDEREÇO</InfoBodyTd>
                                <InfoBodyTd textCenter>QUARTO</InfoBodyTd>
                                <InfoBodyTd textCenter>
                                  REGIME ALIMENTAR
                                </InfoBodyTd>
                                <InfoBodyTd textCenter>CHECKIN</InfoBodyTd>
                                <InfoBodyTd textCenter>CHECKOUT</InfoBodyTd>
                                {allValues != false && (
                                  <InfoBodyTd textCenter>VALOR</InfoBodyTd>
                                )}
                              </tr>
                            )}
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{changeNullForHyphen(e.name)}</td>
                              <td>{changeNullForHyphen(e.fullAddress)}</td>
                              <td>{changeNullForHyphen(e.roomType)}</td>
                              <td>{changeNullForHyphen(e.diet)}</td>
                              <td>{changeNullForDate(e.checkIn)}</td>
                              <td>{changeNullForDate(e.checkOut)}</td>
                              {allValues != false && dolar && (
                                <td>{FormatValueUSD(e.value)}</td>
                              )}
                              {allValues != false && !dolar && (
                                <td>{FormatValueBRL(e.value)}</td>
                              )}
                            </tr>
                          </tbody>
                        </ReactstrapTable>
                        {e?.proposalHotelFile?.length >= 1 && (
                          <PreviewImages files={e?.proposalHotelFile} />
                        )}
                        <div>
                          {e.importantInformation && (
                            <ImportantInformationFooter>
                              <DivForHTML
                                text={
                                  `<b>Informações importantes do hotel ${
                                    i + 1
                                  }: </b><a style="white-space: pre-line">` +
                                  getFirstWords(e.importantInformation, 2000) +
                                  `</a>`
                                }
                              />
                            </ImportantInformationFooter>
                          )}
                        </div>
                      </>
                    ))}
                  </div>
                </>
              )}
              {proposal.name == 'proposalNauticals' && (
                <>
                  <EncapsuleBoxTitle>
                    <p>Náutico</p>
                  </EncapsuleBoxTitle>
                  <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                    {proposal.proposalNauticals.map((e, i) => (
                      <>
                        <ReactstrapTable borderless striped>
                          <tbody>
                            {i == 0 && (
                              <tr>
                                <InfoBodyTd textCenter>N°</InfoBodyTd>
                                <InfoBodyTd textCenter>CIA</InfoBodyTd>
                                <InfoBodyTd textCenter>TIPO</InfoBodyTd>
                                <InfoBodyTd textCenter>CABINE</InfoBodyTd>
                                <InfoBodyTd textCenter>ORIGEM</InfoBodyTd>
                                <InfoBodyTd textCenter>DESTINO</InfoBodyTd>
                                <InfoBodyTd textCenter>PARTIDA</InfoBodyTd>
                                <InfoBodyTd textCenter>CHEGADA</InfoBodyTd>
                                {allValues != false && (
                                  <InfoBodyTd textCenter>VALOR</InfoBodyTd>
                                )}
                              </tr>
                            )}
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{changeNullForHyphen(e.cia)}</td>
                              <td>{changeNullForHyphen(e.type)}</td>
                              <td>{changeNullForHyphen(e.cabinType)}</td>
                              <td>{changeNullForHyphen(e.origin)}</td>
                              <td>{changeNullForHyphen(e.destiny)}</td>
                              <td>{changeNullForDate(e.departure)}</td>
                              <td>{changeNullForDate(e.arrival)}</td>
                              {allValues != false && dolar && (
                                <td>{FormatValueUSD(e.value)}</td>
                              )}
                              {allValues != false && !dolar && (
                                <td>{FormatValueBRL(e.value)}</td>
                              )}
                            </tr>
                          </tbody>
                        </ReactstrapTable>
                        {e?.proposalNauticalFiles?.length >= 1 && (
                          <PreviewImages files={e?.proposalNauticalFiles} />
                        )}
                        <ImportantInformationFooter>
                          <DivForHTML
                            text={
                              `<b>Informações importantes do náutico ${
                                i + 1
                              }: </b><a style="white-space: pre-line">` +
                              getFirstWords(e.importantInformation, 2000) +
                              `</a>`
                            }
                          />
                        </ImportantInformationFooter>
                      </>
                    ))}
                  </div>
                </>
              )}
              {proposal.name == 'proposalVehicleRentals' && (
                <>
                  <EncapsuleBoxTitle>
                    <p>Locações veiculares</p>
                  </EncapsuleBoxTitle>
                  <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                    <ReactstrapTable borderless striped>
                      <tbody>
                        <tr>
                          <InfoBodyTd textCenter>N°</InfoBodyTd>
                          <InfoBodyTd textCenter>Locadora</InfoBodyTd>
                          <InfoBodyTd textCenter>Tipo</InfoBodyTd>
                          <InfoBodyTd textCenter>
                            Endereço de locação
                          </InfoBodyTd>
                          <InfoBodyTd textCenter>Início</InfoBodyTd>
                          <InfoBodyTd textCenter>
                            Endereço de devolução
                          </InfoBodyTd>
                          <InfoBodyTd textCenter>Fim</InfoBodyTd>
                          {allValues != false && (
                            <InfoBodyTd textCenter>Valor</InfoBodyTd>
                          )}
                        </tr>
                        {proposal.proposalVehicleRentals.map((e, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{changeNullForHyphen(e.provider)}</td>
                            <td>{changeNullForHyphen(e.type)}</td>
                            <td>{changeNullForHyphen(e.originAddress)}</td>
                            <td>{changeNullForDate(e.checkIn)}</td>
                            <td>{changeNullForHyphen(e.destinyAddress)}</td>
                            <td>{changeNullForDate(e.checkOut)}</td>
                            {allValues != false && dolar && (
                              <td>{FormatValueUSD(e.value)}</td>
                            )}
                            {allValues != false && !dolar && (
                              <td>{FormatValueBRL(e.value)}</td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </ReactstrapTable>
                    <br></br>
                    {proposal.proposalVehicleRentals.map((e, i) => (
                      <div key={i}>
                        {e.importantInformation && (
                          <ImportantInformationFooter>
                            <DivForHTML
                              text={
                                `<b>Informações importantes da locação veicular ${
                                  i + 1
                                }: </b><a style="white-space: pre-line">` +
                                getFirstWords(e.importantInformation, 2000) +
                                `</a>`
                              }
                            />
                          </ImportantInformationFooter>
                        )}
                      </div>
                    ))}
                  </div>
                </>
              )}
              {proposal.name == 'proposalTickets' && (
                <>
                  <EncapsuleBoxTitle>
                    <p>Ingresso</p>
                  </EncapsuleBoxTitle>
                  <br></br>
                  <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                    <ReactstrapTable borderless striped>
                      <tbody>
                        <tr>
                          <InfoBodyTd textCenter>N°</InfoBodyTd>
                          <InfoBodyTd textCenter>Título</InfoBodyTd>
                          <InfoBodyTd textCenter>Início</InfoBodyTd>
                          <InfoBodyTd textCenter>Fim</InfoBodyTd>
                          {allValues != false && (
                            <InfoBodyTd textCenter>Valor</InfoBodyTd>
                          )}
                        </tr>
                        {proposal.proposalTickets.map((e, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{changeNullForHyphen(e.title)}</td>
                            <td>{changeNullForDate(e.start)}</td>
                            <td>{changeNullForDate(e.end)}</td>
                            {allValues != false && dolar && (
                              <td>{FormatValueUSD(e.value)}</td>
                            )}
                            {allValues != false && !dolar && (
                              <td>{FormatValueBRL(e.value)}</td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </ReactstrapTable>
                    {proposal.proposalTickets.map((e, i) => (
                      <div key={i}>
                        {e.desc && (
                          <ImportantInformationFooter>
                            <DivForHTML
                              text={
                                `<b>Informações importantes da locação veicular ${
                                  i + 1
                                }: </b><a style="white-space: pre-line">` +
                                getFirstWords(e.desc, 2000) +
                                `</a>`
                              }
                            />
                          </ImportantInformationFooter>
                        )}
                      </div>
                    ))}
                  </div>
                </>
              )}
              {proposal.name == 'proposalOtherServices' && (
                <>
                  <EncapsuleBoxTitle>
                    <p>Outros serviços</p>
                  </EncapsuleBoxTitle>
                  <br></br>
                  <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                    <ReactstrapTable borderless striped>
                      <tbody>
                        <tr>
                          <InfoBodyTd textCenter>N°</InfoBodyTd>
                          <InfoBodyTd textCenter>Título</InfoBodyTd>
                          <InfoBodyTd textCenter>Endereço</InfoBodyTd>
                          <InfoBodyTd textCenter>Cidade</InfoBodyTd>
                          <InfoBodyTd textCenter>Início</InfoBodyTd>
                          <InfoBodyTd textCenter>Fim</InfoBodyTd>
                          {allValues != false && (
                            <InfoBodyTd textCenter>Valor</InfoBodyTd>
                          )}
                        </tr>
                        {proposal.proposalOtherServices.map((e, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{changeNullForHyphen(e.title)}</td>
                            <td>{changeNullForHyphen(e.startAddress)}</td>
                            <td>{changeNullForHyphen(e.city)}</td>
                            <td>{changeNullForDate(e.start)}</td>
                            <td>{changeNullForDate(e.end)}</td>
                            {allValues != false && dolar && (
                              <td>{FormatValueUSD(e.value)}</td>
                            )}
                            {allValues != false && !dolar && (
                              <td>{FormatValueBRL(e.value)}</td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </ReactstrapTable>

                    {proposal.proposalOtherServices.map((e, i) => (
                      <div key={i}>
                        {e.desc && (
                          <ImportantInformationFooter>
                            <DivForHTML
                              text={
                                `<b>Informações importantes de outros serviços ${
                                  i + 1
                                }: </b><a style="white-space: pre-line">` +
                                getFirstWords(e.desc, 2000) +
                                `</a>`
                              }
                            />
                          </ImportantInformationFooter>
                        )}
                        <br />
                      </div>
                    ))}
                  </div>
                </>
              )}

              {proposal.name == 'proposalDestinys' && (
                <>
                  <EncapsuleBoxTitle>
                    <p>Destinos</p>
                  </EncapsuleBoxTitle>
                  <br></br>
                  <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                    {proposal?.proposalDestinys.some(
                      y => y.proposalDestinyFiles?.length >= 1,
                    ) ? (
                      <>
                        {proposal.proposalDestinys.map((e, i) => (
                          <>
                            <ReactstrapTable borderless striped>
                              <tbody>
                                {i == 0 && (
                                  <tr
                                    styles={{
                                      backgroundColor: primaryColor,
                                      // textAlign: 'center',
                                    }}
                                  >
                                    <InfoBodyTd textCenter>N°</InfoBodyTd>
                                    <InfoBodyTd textCenter>Nome</InfoBodyTd>
                                    <InfoBodyTd textCenter>Início</InfoBodyTd>
                                    <InfoBodyTd textCenter>Fim</InfoBodyTd>
                                  </tr>
                                )}
                                <tr key={i}>
                                  <Td>{i + 1}</Td>
                                  <Td>{changeNullForHyphen(e.name)}</Td>
                                  <Td>{changeNullForDate(e.dateStart)}</Td>
                                  <Td>{changeNullForDate(e.dateEnd)}</Td>
                                </tr>
                              </tbody>
                            </ReactstrapTable>
                            {e?.proposalDestinyFiles?.length >= 1 && (
                              <PreviewImages files={e?.proposalDestinyFiles} />
                            )}
                            <br />
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        <ReactstrapTable borderless striped>
                          <tbody>
                            <tr>
                              <InfoBodyTd textCenter>N°</InfoBodyTd>
                              <InfoBodyTd textCenter>Nome</InfoBodyTd>
                              <InfoBodyTd textCenter>Início</InfoBodyTd>
                              <InfoBodyTd textCenter>Fim</InfoBodyTd>
                              {allValues != false && (
                                <InfoBodyTd textCenter>Valor</InfoBodyTd>
                              )}
                            </tr>
                            {proposal.proposalDestinys.map((e, i) => (
                              <>
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>{changeNullForHyphen(e.name)}</td>
                                  <td>{changeNullForDate(e.dateStart)}</td>
                                  <td>{changeNullForDate(e.dateEnd)}</td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </ReactstrapTable>
                      </>
                    )}
                  </div>
                </>
              )}

              {proposal.name == 'proposalTransfers' && (
                <>
                  <EncapsuleBoxTitle>
                    <p>Transfers</p>
                  </EncapsuleBoxTitle>
                  <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                    {proposal.proposalTransfers.map((e, i) => (
                      <>
                        <ReactstrapTable borderless striped>
                          <tbody>
                            {i == 0 && (
                              <tr>
                                <InfoBodyTd textCenter>N°</InfoBodyTd>
                                <InfoBodyTd textCenter>Origem</InfoBodyTd>
                                <InfoBodyTd textCenter>
                                  Endereço de origem
                                </InfoBodyTd>
                                <InfoBodyTd textCenter>Destino</InfoBodyTd>
                                <InfoBodyTd textCenter>
                                  Endereço de destino
                                </InfoBodyTd>
                                <InfoBodyTd textCenter>Checkin</InfoBodyTd>
                                <InfoBodyTd textCenter>Chekout</InfoBodyTd>
                                {allValues != false && (
                                  <InfoBodyTd textCenter>Valor</InfoBodyTd>
                                )}
                              </tr>
                            )}
                            <tr key={i}>
                              <Td>{i + 1}</Td>
                              <Td>{changeNullForHyphen(e.origin)}</Td>
                              <Td>{changeNullForHyphen(e.originAddress)}</Td>
                              <Td>{changeNullForHyphen(e.destiny)}</Td>{' '}
                              <Td>{changeNullForHyphen(e.destinyAddress)}</Td>
                              <Td>{changeNullForDate(e.checkIn)}</Td>
                              <Td>{changeNullForDate(e.checkOut)}</Td>
                              {allValues != false && dolar && (
                                <Td>{FormatValueUSD(e.value)}</Td>
                              )}
                              {allValues != false && !dolar && (
                                <Td>{FormatValueBRL(e.value)}</Td>
                              )}
                            </tr>
                          </tbody>
                        </ReactstrapTable>
                        {e?.proposalTransferFiles?.length >= 1 && (
                          <PreviewImages files={e?.proposalTransferFiles} />
                        )}
                        <ImportantInformationFooter>
                          <DivForHTML
                            text={
                              `<b>Informações importantes do náutico ${
                                i + 1
                              }: </b><a style="white-space: pre-line">` +
                              getFirstWords(e.importantInformation, 2000) +
                              `</a>`
                            }
                          />
                        </ImportantInformationFooter>
                      </>
                    ))}
                  </div>
                </>
              )}

              {proposal.name == 'proposalInsurances' && (
                <>
                  <EncapsuleBoxTitle>
                    <p>Seguros</p>
                  </EncapsuleBoxTitle>
                  <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                    {proposal?.proposalInsurances?.map((e, i) => (
                      <>
                        <ReactstrapTable borderless striped>
                          <tbody>
                            {i == 0 && (
                              <tr>
                                <InfoBodyTd textCenter>N°</InfoBodyTd>
                                <InfoBodyTd textCenter>
                                  Tipo de cobertura
                                </InfoBodyTd>
                                <InfoBodyTd textCenter>Início</InfoBodyTd>
                                <InfoBodyTd textCenter>Fim</InfoBodyTd>
                                {allValues != false && (
                                  <InfoBodyTd textCenter>Valor</InfoBodyTd>
                                )}
                              </tr>
                            )}
                            <tr key={i}>
                              <Td>{i + 1}</Td>
                              <Td>{changeNullForHyphen(e.typeCoverage)}</Td>
                              <Td>{changeNullForDate(e.timeStart)}</Td>
                              <Td>{changeNullForDate(e.timeEnd)}</Td>
                              {allValues != false && (
                                <Td>{changeNullForDate(e.value)}</Td>
                              )}
                            </tr>
                          </tbody>
                        </ReactstrapTable>
                        {e?.proposalInsuranceFiles?.length >= 1 && (
                          <PreviewImages files={e?.proposalInsuranceFiles} />
                        )}
                        <ImportantInformationFooter>
                          <DivForHTML
                            text={
                              `<b>Informações importantes do náutico ${
                                i + 1
                              }: </b><a style="white-space: pre-line">` +
                              getFirstWords(e.description, 2000) +
                              `</a>`
                            }
                          />
                        </ImportantInformationFooter>
                      </>
                    ))}
                  </div>
                </>
                // <>
                //   <EncapsuleBoxTitle>
                //     <p>Seguros</p>
                //   </EncapsuleBoxTitle>
                //   <br></br>
                //   <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                //     <ReactstrapTable borderless striped>
                //       <tbody>
                //         <tr>
                //           <InfoBodyTd textCenter>N°</InfoBodyTd>
                //           <InfoBodyTd textCenter>Tipo de cobertura</InfoBodyTd>
                //           <InfoBodyTd textCenter>Início</InfoBodyTd>
                //           <InfoBodyTd textCenter>Fim</InfoBodyTd>
                //           {allValues != false && (
                //             <InfoBodyTd textCenter>Valor</InfoBodyTd>
                //           )}
                //         </tr>
                //         {proposal.proposalInsurances.map((e, i) => (
                //           <tr key={i}>
                //             <td>{i + 1}</td>
                //             <td>{changeNullForHyphen(e.typeCoverage)}</td>
                //             <td>{changeNullForDate(e.timeStart)}</td>
                //             <td>{changeNullForDate(e.timeEnd)}</td>
                //             {allValues != false && (
                //               <td>{changeNullForDate(e.value)}</td>
                //             )}
                //           </tr>
                //         ))}
                //       </tbody>
                //     </ReactstrapTable>
                //   </div>
                //   {proposal.proposalInsurances.map((e, i) => (
                //     <div key={i}>
                //       {e.description && (
                //         <ImportantInformationFooter>
                //           <DivForHTML
                //             text={
                //               `<b>Informações importantes da atividade ${
                //                 i + 1
                //               }: </b><a style="white-space: pre-line">` +
                //               getFirstWords(e.description, 2000) +
                //               `</a>`
                //             }
                //           />
                //         </ImportantInformationFooter>
                //       )}
                //     </div>
                //   ))}
                // </>
              )}

              {proposal.name == 'proposalActivitys' && (
                <>
                  <EncapsuleBoxTitle>
                    <p>Atividades</p>
                  </EncapsuleBoxTitle>
                  <br></br>
                  <ReactstrapTable striped borderless>
                    <tbody>
                      <tr>
                        <InfoBodyTd textCenter>N°</InfoBodyTd>
                        <InfoBodyTd textCenter>Endereço</InfoBodyTd>
                        <InfoBodyTd textCenter>Pickup</InfoBodyTd>
                        <InfoBodyTd textCenter>Início</InfoBodyTd>
                        <InfoBodyTd textCenter>Fim</InfoBodyTd>
                        {allValues != false && (
                          <InfoBodyTd textCenter>Valor</InfoBodyTd>
                        )}
                      </tr>
                      {proposal.proposalActivitys.map((e, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{changeNullForHyphen(e.startAddress)}</td>
                          <td>{changeNullForDate(e.pickUp)}</td>
                          <td>{changeNullForDate(e.start)}</td>
                          <td>{changeNullForDate(e.end)}</td>
                          {allValues != false && (
                            <td>{changeNullForDate(e.value)}</td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </ReactstrapTable>
                  {proposal.proposalActivitys.map((e, i) => (
                    <div key={i}>
                      {e.desc && (
                        <ImportantInformationFooter>
                          <DivForHTML
                            text={
                              `<b>Informações importantes da atividade ${
                                i + 1
                              }: </b><a style="white-space: pre-line">` +
                              getFirstWords(e.desc, 2000) +
                              `</a>`
                            }
                          />
                        </ImportantInformationFooter>
                      )}
                    </div>
                  ))}
                </>
              )}

              {proposal.included && proposal.included != '<p><br></p>' && (
                <>
                  <EncapsuleBoxTitle>
                    <p>Nosso roteiro inclui</p>
                  </EncapsuleBoxTitle>
                  <ImportantInformationFooter>
                    <DivForHTML text={getFirstWords(proposal.included, 2000)} />
                  </ImportantInformationFooter>
                </>
              )}
              {proposal.alter && proposal.alter != " != '<p><br></p>' &&" && (
                <>
                  <EncapsuleBoxTitle>
                    <p>Condições de alterações</p>
                  </EncapsuleBoxTitle>
                  <ImportantInformationFooter>
                    <DivForHTML text={getFirstWords(proposal.alter, 2000)} />
                  </ImportantInformationFooter>
                </>
              )}
              {proposal.cancel && proposal.cancel != " != '<p><br></p>' &&" && (
                <>
                  <EncapsuleBoxTitle>
                    <p>Condições de cancelamento</p>
                  </EncapsuleBoxTitle>
                  <ImportantInformationFooter>
                    <DivForHTML text={getFirstWords(proposal.cancel, 2000)} />
                  </ImportantInformationFooter>
                </>
              )}
              {proposal.countryExi &&
                proposal.countryExi != " != '<p><br></p>' &&" && (
                  <>
                    <EncapsuleBoxTitle>
                      <p>Exigências dos países</p>
                    </EncapsuleBoxTitle>
                    <ImportantInformationFooter>
                      <DivForHTML
                        text={getFirstWords(proposal.countryExi, 2000)}
                      />
                    </ImportantInformationFooter>
                  </>
                )}
              {proposal.notIncluded &&
                proposal.notIncluded != " != '<p><br></p>' &&" && (
                  <>
                    <EncapsuleBoxTitle>
                      <p>Nosso roteiro não inclui</p>
                    </EncapsuleBoxTitle>
                    <ImportantInformationFooter>
                      <DivForHTML
                        text={getFirstWords(proposal.notIncluded, 2000)}
                      />
                    </ImportantInformationFooter>
                  </>
                )}
              {proposal.reservation &&
                proposal.reservation != " != '<p><br></p>' &&" && (
                  <>
                    <EncapsuleBoxTitle>
                      <p>Condições de reserva</p>
                    </EncapsuleBoxTitle>
                    <ImportantInformationFooter>
                      <DivForHTML
                        text={getFirstWords(proposal.reservation, 2000)}
                      />
                    </ImportantInformationFooter>
                  </>
                )}
            </DivEntity>
          ))}

          {!showTotalValue && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                borderBottom: '1px solid #6c6c6c33',
                padding: '20px 20px',
              }}
            >
              <p>
                <b>TOTAL</b>
              </p>
              <p>R$ {formatterValue(fullValue)}</p>
            </div>
          )}
        </Container>
        <Footer image={agency} />
      </DivGlobal>
    </div>
  )
}
