import styled from 'styled-components'

export const MainImageContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;

  /* @media screen and (width < 1536px) {
    height: 360px;
  }
  @media screen and (width < 1352px) {
    height: 300px;
  } */
`

export const MainImage = styled.img`
  width: 100%;
  height: 100%;
  padding: 0 4px 0 4px;
  object-fit: cover;
`

export const ImageGridItemContainer = styled.div`
  width: 50%;
  height: 50%;
  padding: 2px 2px 2px 4px;
`

export const ImageGridItem = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const DoubleImageContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
`

export const DoubleImageContainerInsideTop = styled.div`
  padding: 2px 0px 4px 4px;
  width: 100%;
  height: 100%;
`
export const DoubleImageContainerInsideBottom = styled.div`
  padding: 4px 0px 2px 4px;
  width: 100%;
  height: 100%;
`

export const QuadrupleImageContainerInsideTop = styled.div`
  padding: ${props => (props.right ? '4px 0px 2px 4px' : '4px 2px 2px 4px')};
  width: 100%;
  height: 50%;
`
export const QuadrupleImageContainerInsideBottom = styled.div`
  padding: ${props => (props.right ? '4px 0px 2px 4px' : '4px 2px 2px 4px')};
  width: 100%;
  height: 50%;
`
