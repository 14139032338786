import { Accordion, Button } from 'reactstrap'
import styled from 'styled-components'

export const InfomationAccordion = styled(Accordion)`
  width: 100%;

  .accordion-body {
    padding: 8px;
  }
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  gap: 8px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-behavior: smooth;
`

export const ServiceButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background: #eaeaea;
  padding: 8px;
  border-bottom: 1px solid #ccc;
`

export const ServiceButton = styled(Button)`
  height: 38px;
  color: #555;
  background: none;
  border: none;
  font-size: 1.2em;

  &:hover {
    color: #555;
    background: rgba(0, 0, 0, 0.1);
  }
`

export const AvatarIcon = styled.i`
  font-size: 64px;
  color: #bdbfbf;
`
