import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import { AvatarSoft } from '~/components/Header/styles'

export const ContactsSection = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  border-right: 1px solid #ccc;
`

export const ContactImage = styled(AvatarSoft)`
  @media (max-width: 767.5px) {
    width: 52px;
    height: 52px;
  }
`

export const Icon = styled.i`
  color: #777;
  padding: 0px 6px;
  font-size: 12px;
`

export const AccordionTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const Container = styled(Row)`
  padding: 6px !important;
  align-items: center;
  margin: 0px;
  border-bottom: 1px solid #caccce;
  cursor: pointer;
  @media (max-width: 767.5px) {
    border: none;
  }

  background: ${props => (!props.active ? 'transparent' : '#eaeaea')};
`

export const Message = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: lighter;
  margin-bottom: 0px;
`

export const ContentLeft = styled(Col)`
  @media (max-width: 767.5px) {
    display: none;
  }
`

export const ContentRight = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 767.5px) {
    display: none;
  }
  gap: 2px;
`
