export const inputs = {
  destiny: [
    {
      type: 'date',
      name: 'dateStart',
      label: 'Data inicial',
      required: true,
      sm: 4,
      lg: 4,
      xl: 4,
    },
    {
      type: 'date',
      name: 'dateEnd',
      label: 'Data final',
      required: true,
      sm: 4,
      lg: 4,
      xl: 4,
    },
    {
      type: 'city',
      name: 'name',
      placeholder: 'Escolha uma cidade...',
      label: 'Nome',
      isClearable: true,
      required: true,
      sm: 4,
      lg: 4,
      xl: 4,
    },
    {
      type: 'inputFile',
      name: 'files',
      label: 'Anexos',
      max: 5,
      sm: 12,
      lg: 12,
    },
  ],
  flight: [
    {
      type: 'text',
      name: 'descriptionPayment',
      label: 'Forma de pagamento',
      sm: 12,
      lg: 2,
    },
    {
      type: 'number',
      name: 'value',
      label: 'Valor',
      min: 0,
      sm: 12,
      lg: 2,
    },
    {
      type: 'number',
      name: 'passengers',
      label: 'N. de adultos',
      required: true,
      min: 0,
      sm: 12,
      lg: 2,
    },
    {
      type: 'number',
      name: 'numberOfChildren',
      label: 'N. de crianças',
      min: 0,
      sm: 12,
      lg: 2,
    },
    {
      type: 'number',
      name: 'numberOfBabies',
      label: 'N. de bebês',
      min: 0,
      sm: 12,
      lg: 2,
    },
    {
      type: 'select',
      name: 'baggage',
      label: 'Bagagem',
      required: true,
      options: [
        { label: 'Sim', value: true },
        { label: 'Não', value: false },
      ],
      sm: 12,
      lg: 2,
    },
    {
      type: 'select',
      name: 'type',
      label: 'Tipo',
      required: true,
      options: [
        { label: 'Ida', value: 'Ida' },
        { label: 'Ida e volta', value: 'Ida e Volta' },
      ],
      sm: 12,
      lg: 2,
    },
    {
      type: 'textarea',
      name: 'importantInformation',
      label: 'Informações importantes',
      sm: 12,
    },
    {
      type: 'inputFile',
      name: 'files',
      label: 'Anexos',
      max: 5,
      sm: 12,
      lg: 12,
    },
  ],
  hotel: [
    {
      type: 'text',
      name: 'name',
      label: 'Nome',
      required: true,
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'text',
      name: 'fullAddress',
      label: 'Endereço',
      required: true,
      sm: 3,
      lg: 3,
      xl: 3,
    },
    {
      type: 'text',
      name: 'locationName',
      label: 'Cidade',
      required: true,
      sm: 3,
      lg: 3,
      xl: 3,
    },
    {
      type: 'text',
      name: 'roomType',
      label: 'Tipo do quarto',
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'text',
      name: 'diet',
      label: 'Regime alimentar',
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'date',
      name: 'dateCheckIn',
      label: 'Checkin',
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'time',
      name: 'timeCheckIn',
      label: 'Horário do checkin',
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'date',
      name: 'dateCheckOut',
      label: 'Checkout',
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'time',
      name: 'timeCheckOut',
      label: 'Horário do checkout',
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'number',
      min: 0,
      name: 'value',
      label: 'Valor',
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'text',
      name: 'descriptionPayment',
      label: 'Forma de pagamento',
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'textarea',
      name: 'importantInformation',
      label: 'Informações importantes',
      sm: 12,
      lg: 12,
      xl: 12,
    },
    {
      type: 'inputFile',
      name: 'files',
      label: 'Anexos',
      max: 5,
      sm: 12,
      lg: 12,
    },
  ],
  activity: [
    {
      type: 'text',
      name: 'startAddress',
      label: 'Local',
      required: true,
      sm: 12,
      lg: 5,
      xl: 5,
    },
    {
      type: 'date',
      name: 'date',
      label: 'Data',
      // required: true,
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'time',
      name: 'start',
      label: 'Horário',
      // required: true,
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'number',
      min: 0,
      name: 'value',
      label: 'Valor',
      // required: true,
      sm: 3,
      lg: 1,
      xl: 1,
    },
    {
      type: 'text',
      name: 'descriptionPayment',
      label: 'Forma de pagamento',
      sm: 3,
      lg: 2,
      xl: 2,
    },
    {
      type: 'textarea',
      name: 'desc',
      label: 'Descrição',
      required: true,
      sm: 12,
      lg: 12,
      xl: 12,
    },
    // {
    //   type: 'inputFile',
    //   name: 'files',
    //   label: 'Anexos',
    //   max: 5,
    //   sm: 12,
    //   lg: 12,
    // },
  ],
  insurance: [
    {
      type: 'date',
      name: 'timeStart',
      label: 'Início',
      required: true,
      sm: 4,
      lg: 4,
      xl: 4,
    },
    {
      type: 'date',
      name: 'timeEnd',
      label: 'Fim',
      required: true,
      sm: 4,
      lg: 4,
      xl: 4,
    },
    {
      type: 'text',
      name: 'typeCoverage',
      label: 'Tipo de cobertura',
      required: true,
      sm: 4,
      lg: 4,
      xl: 4,
    },
    {
      type: 'number',
      min: 0,
      name: 'value',
      label: 'Valor',
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'text',
      name: 'descriptionPayment',
      label: 'Forma de pagamento',
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'textarea',
      name: 'description',
      label: 'Descrição da cobertura do seguro',
      sm: 12,
      lg: 12,
      xl: 12,
    },
    // {
    //   type: 'inputFile',
    //   name: 'files',
    //   label: 'Anexos',
    //   max: 5,
    //   sm: 12,
    //   lg: 12,
    // },
  ],
  nautical: [
    {
      type: 'text',
      name: 'cia',
      label: 'Cia',
      required: true,
      sm: 6,
      lg: 6,
      xl: 1,
    },
    {
      type: 'select',
      name: 'type',
      maxLength: 100,
      label: 'Tipo do náutico',
      options: [
        { label: 'Cruzeiro', value: 'Cruzeiro' },
        { label: 'Iate', value: 'Iate' },
        { label: 'Bote', value: 'Bote' },
        { label: 'Escuna', value: 'Escuna' },
        { label: 'Jangada', value: 'Jangada' },
        { label: 'Veleiro', value: 'Veleiro' },
        { label: 'Outros', value: 'Outros' },
      ],
      required: true,
      sm: 6,
      md: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'text',
      name: 'cabinType',
      label: 'Tipo da cabine',
      sm: 6,
      lg: 6,
      xl: 1,
    },
    {
      type: 'number',
      min: 0,
      name: 'value',
      label: 'Valor',
      // required: true,
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'text',
      name: 'descriptionPayment',
      label: 'Forma de pagamento',
      // required: true,
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'text',
      name: 'seaportOrigin',
      label: 'Porto de origem',
      // required: true,
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'text',
      name: 'origin',
      label: 'Origem',
      required: true,
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'date',
      name: 'departure',
      label: 'Partida',
      required: true,
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'time',
      name: 'start',
      label: 'Horário de partida',
      required: true,
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'text',
      name: 'seaportDestiny',
      label: 'Porto de destino',
      // required: true,
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'text',
      name: 'destiny',
      label: 'Destino',
      required: true,
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'date',
      name: 'arrival',
      label: 'Chegada',
      required: true,
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'time',
      name: 'end',
      label: 'Horário de chegada',
      required: true,
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'textarea',
      name: 'importantInformation',
      label: 'Informações importantes',
      sm: 12,
      lg: 12,
      xl: 12,
    },
    {
      type: 'inputFile',
      name: 'files',
      label: 'Anexos',
      max: 5,
      sm: 12,
      lg: 12,
    },
  ],
  otherService: [
    {
      type: 'text',
      name: 'title',
      label: 'Titulo',
      required: true,
      sm: 4,
      lg: 4,
      xl: 4,
    },
    {
      type: 'text',
      name: 'startAddress',
      label: 'Endereço',
      sm: 4,
      lg: 4,
      xl: 4,
    },
    {
      type: 'city',
      name: 'city',
      label: 'Cidade',
      sm: 4,
      lg: 4,
      xl: 4,
    },
    {
      type: 'date',
      name: 'dateCheckIn',
      label: 'Data de início',
      required: true,
      sm: 3,
      lg: 3,
      xl: 3,
    },
    {
      type: 'time',
      name: 'timeCheckIn',
      label: 'Horário do início',
      required: true,
      sm: 3,
      lg: 3,
      xl: 3,
    },
    {
      type: 'date',
      name: 'dateCheckOut',
      label: 'Data do fim',
      required: true,
      sm: 3,
      lg: 3,
      xl: 3,
    },
    {
      type: 'time',
      name: 'timeCheckOut',
      label: 'Horário do fim',
      required: true,
      sm: 3,
      lg: 3,
      xl: 3,
    },
    {
      type: 'number',
      min: 0,
      name: 'value',
      label: 'Valor',
      // required: true,
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'text',
      name: 'descriptionPayment',
      label: 'Forma de pagamento',
      // required: true,
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'html',
      name: 'desc',
      label: 'Descrição',
      required: true,
      sm: 12,
      lg: 12,
      xl: 12,
    },
    // {
    //   type: 'inputFile',
    //   name: 'files',
    //   label: 'Anexos',
    //   max: 5,
    //   sm: 12,
    //   lg: 12,
    // },
  ],
  ticket: [
    {
      type: 'text',
      name: 'title',
      label: 'Título',
      required: true,
      sm: 4,
      md: 4,
      lg: 4,
      xl: 4,
    },
    {
      type: 'number',
      min: 0,
      name: 'value',
      label: 'Valor',
      sm: 4,
      lg: 4,
      xl: 4,
    },
    {
      type: 'text',
      name: 'descriptionPayment',
      label: 'Forma de pagamento',
      sm: 4,
      lg: 4,
      xl: 4,
    },
    {
      type: 'date',
      name: 'dateStart',
      label: 'Data do Início',
      row: '2',
      required: true,
      sm: 12,
      md: 6,
      lg: 4,
      xl: 3,
    },
    {
      type: 'time',
      name: 'timeStart',
      label: 'Hora do Início',
      required: true,
      sm: 12,
      md: 6,
      lg: 4,
      xl: 3,
    },
    {
      type: 'date',
      name: 'dateEnd',
      label: 'Data do Fim',
      required: true,
      sm: 12,
      md: 6,
      lg: 4,
      xl: 3,
    },
    {
      type: 'time',
      name: 'timeEnd',
      label: 'Hora do Fim',
      required: true,
      sm: 12,
      md: 6,
      lg: 4,
      xl: 3,
    },
    {
      type: 'textarea',
      name: 'desc',
      label: 'Descrição',
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
    },
    // {
    //   type: 'inputFile',
    //   name: 'files',
    //   label: 'Anexos',
    //   max: 5,
    //   sm: 12,
    //   lg: 12,
    // },
  ],
  train: [
    {
      type: 'text',
      name: 'stationOrigin',
      label: 'Estação de origem',
      // required: true,
      sm: 6,
      lg: 6,
      xl: 3,
    },
    {
      type: 'text',
      name: 'origin',
      label: 'Origem',
      required: true,
      sm: 6,
      lg: 6,
      xl: 3,
    },
    {
      type: 'date',
      name: 'departure',
      label: 'Partida',
      required: true,
      sm: 6,
      lg: 6,
      xl: 3,
    },
    {
      type: 'time',
      name: 'start',
      label: 'Horário de partida',
      required: true,
      sm: 6,
      lg: 6,
      xl: 3,
    },
    {
      type: 'text',
      name: 'stationDestiny',
      label: 'Estação de destino',
      // required: true,
      sm: 6,
      lg: 6,
      xl: 3,
    },
    {
      type: 'text',
      name: 'destiny',
      label: 'Destino',
      required: true,
      sm: 6,
      lg: 6,
      xl: 3,
    },
    {
      type: 'date',
      name: 'arrival',
      label: 'Chegada',
      required: true,
      sm: 6,
      lg: 6,
      xl: 3,
    },
    {
      type: 'time',
      name: 'end',
      label: 'Horário de chegada',
      required: true,
      sm: 6,
      lg: 6,
      xl: 3,
    },
    {
      type: 'text',
      name: 'cia',
      label: 'Cia',
      required: true,
      sm: 6,
      lg: 6,
      xl: 1,
    },
    {
      type: 'number',
      min: 0,
      name: 'value',
      label: 'Valor',
      // required: true,
      sm: 6,
      lg: 6,
      xl: 1,
    },
    {
      type: 'text',
      name: 'descriptionPayment',
      label: 'Forma de pagamento',
      // required: true,
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'textarea',
      name: 'importantInformation',
      label: 'Informações importantes',
      sm: 12,
      lg: 12,
      xl: 12,
    },
    {
      type: 'inputFile',
      name: 'files',
      label: 'Anexos',
      max: 5,
      sm: 12,
      lg: 12,
    },
  ],
  transfer: [
    {
      type: 'text',
      name: 'originAddress',
      label: 'Local de partida',
      required: true,
      sm: 6,
      lg: 6,
      xl: 4,
    },
    {
      type: 'city',
      name: 'origin',
      label: 'Cidade do transfer',
      required: true,
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'date',
      name: 'dateCheckIn',
      label: 'Início',
      required: true,
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'time',
      name: 'timeCheckIn',
      label: 'Horário do início',
      required: true,
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'number',
      min: 0,
      name: 'value',
      label: 'Valor',
      // required: true,
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'text',
      name: 'destinyAddress',
      label: 'Local de chegada',
      required: true,
      sm: 6,
      lg: 6,
      xl: 4,
    },
    {
      type: 'city',
      name: 'destiny',
      label: 'Cidade da chegada',
      required: true,
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'date',
      name: 'dateCheckOut',
      label: 'Fim',
      required: true,
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'time',
      name: 'timeCheckOut',
      label: 'Horário do fim',
      required: true,
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'text',
      name: 'descriptionPayment',
      label: 'Forma de pagamento',
      // required: true,
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'textarea',
      name: 'importantInformation',
      label: 'Informações importantes',
      sm: 12,
      lg: 12,
      xl: 12,
    },
    {
      type: 'inputFile',
      name: 'files',
      label: 'Anexos',
      max: 5,
      sm: 12,
      lg: 12,
    },
  ],
  vehicleRental: [
    {
      type: 'text',
      name: 'provider',
      label: 'Locadora',
      sm: 3,
      md: 3,
      lg: 3,
      xl: 3,
    },
    {
      type: 'number',
      min: 0,
      name: 'value',
      label: 'Valor',
      sm: 3,
      lg: 3,
      xl: 3,
    },
    {
      type: 'text',
      name: 'descriptionPayment',
      label: 'Forma de pagamento',
      sm: 3,
      lg: 3,
      xl: 3,
    },
    {
      type: 'select',
      name: 'type',
      maxLength: 100,
      label: 'Tipo do veículo',
      options: [
        { label: 'Hatch', value: 'Hatch' },
        { label: 'Sedan', value: 'Sedan' },
        { label: 'SUV', value: 'SUV' },
        { label: 'Crossover', value: 'Crossover' },
        { label: 'Minivan', value: 'Minivan' },
        { label: 'Station Wagon', value: 'Station Wagon' },
        { label: 'Cupê', value: 'Cupê' },
      ],
      sm: 3,
      md: 3,
      lg: 3,
      xl: 3,
    },
    {
      type: 'text',
      name: 'originAddress',
      label: 'Endereço de locação',
      required: true,
      sm: 8,
      md: 8,
      lg: 8,
      xl: 8,
    },
    {
      type: 'date',
      name: 'dateCheckIn',
      label: 'Data de locação',
      required: true,
      sm: 2,
      md: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'time',
      name: 'timeCheckIn',
      label: 'Horário de locação',
      required: true,
      sm: 2,
      md: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'text',
      name: 'destinyAddress',
      label: 'Endereço de devolução',
      required: true,
      sm: 8,
      md: 8,
      lg: 8,
      xl: 8,
    },
    {
      type: 'date',
      name: 'dateCheckOut',
      label: 'Data de devolução',
      required: true,
      sm: 2,
      md: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'time',
      name: 'timeCheckOut',
      label: 'Horário de devolução',
      required: true,
      sm: 2,
      md: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'textarea',
      name: 'importantInformation',
      label: 'Informações importantes',
      sm: 12,
      lg: 12,
      xl: 12,
    },
    // {
    //   type: 'inputFile',
    //   name: 'files',
    //   label: 'Anexos',
    //   max: 5,
    //   sm: 12,
    //   lg: 12,
    // },
  ],
}
