import { Button } from 'reactstrap'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: #eaeaea;
  border-bottom: 1px solid #ccc;
`

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const TextInput = styled.div`
  flex: 1;
`

export const ActionButton = styled(Button)`
  width: 36px;
  height: 36px;
  border: none;
  font-weight: 700;
  padding: 0;
`

export const Icon = styled.i`
  color: #777;
  font-size: 12px;
  padding: 0;
`

export const Filters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
`

export const FiltersPreview = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  margin-top: 8px;
`

export const FiltersPreviewItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 6px;
  padding: 4px 8px;
  border: 1px solid #ced4da;
`

export const FiltersPreviewItemLabel = styled.label`
  font-weight: 600;
`

export const FiltersPreviewItemValue = styled.div`
  font-weight: 400;

  .p-rating-icon {
    font-size: 1em;
  }
`
