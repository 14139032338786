import { useState, useContext, useEffect } from 'react'
import context from '~/context/createContext'
import { Row, Col } from 'reactstrap'
import { getMoment, getDateFull } from '~/functions/Date'
import {
  getTravelsByDay,
  getTravelsByMonth,
  getRemindersByDay,
  getActuallyTravels,
  getNextTravels,
  getPastTravels,
  getDepartureByDay,
  getDepartureByMonth,
} from '~/functions/FilterTravels'
import LoadingContainer from '~/components/LoadingContainer'
import { LinkCreate } from '~/components/Link/styles'
import CalendarSchedule from '~/components/CalendarSchedule'
import Header from './components/Header'
import { CalendarDay } from './components/CalendarDay'
import { CardDetails } from './components/CardDetails'
import { Container } from '~/styles/container'
import { DivFlexRight, SpaceBetweenDiv } from '~/styles/divs'
import { CancelButton } from '~/components/Button/styles'
import Divisor from '~/components/Divisor'
import ModalDefault from '~/components/ModalDefault'
import ReminderTable from './reminderTable'
import { apiRequest } from '~/apiService'
import { toast } from 'react-toastify'
import { GetAxios } from '~/services/api'

export default function Schedule() {
  const { axiosApis } = GetAxios()
  const [type, setType] = useState('month')
  const [day, setDay] = useState(getMoment())
  const [month, setMonth] = useState(getMoment())
  const [showModal, setShowModal] = useState(localStorage.getItem('Q937p@qC'))
  const [loading, setLoading] = useState(false)
  const [reminders, setReminders] = useState([])
  const [entitys, setEntities] = useState([])
  const [columns, setColumns] = useState(4)

  const { loadingTravelers } = useContext(context)

  useEffect(() => {
    showModal == 'true' && getReminders()
    getEntities()
  }, [])

  const getEntities = async () => {
    try {
      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/schedule/travels`,
      )

      if (data?.filter(x => x.typeInfo == 'Embarque').length >= 1) {
        setColumns(3)
      }

      console.log(data)

      setEntities(data)
    } catch (err) {
      setEntities([])
    }
  }

  async function onClickDay(date) {
    if (
      date.format('YYYY-MM-DD') === day.format('YYYY-MM-DD') &&
      type === 'day'
    ) {
      return setType('month')
    }

    setDay(date)

    setType('day')
  }

  async function onChangeMonth(date) {
    setMonth(date)

    setType('month')
  }

  function renderDay(locale, date) {
    return (
      <CalendarDay entitys={entitys} locale={locale} date={date} type={type} />
    )
  }

  const getReminders = async () => {
    const response = await apiRequest(
      'get',
      'reminder',
      { selfOnly: true },
      setLoading,
    )

    if (response.error == false) {
      setReminders(response?.data)

      if (response.data.length == 0) localStorage.setItem('Q937p@qC', false)
    } else {
      localStorage.setItem('Q937p@qC', false)
    }
  }

  const UpdateReminderDone = async id => {
    const response = await apiRequest(
      'put',
      `reminder/done/${id}`,
      {},
      setLoading,
    )

    if (response.error == false) await getReminders()
    else toast.info(response.erroMessage)
  }

  return (
    <Container>
      {/* <BeamerHelmet
        id='OPXbHTJj66131'
        url='https://app.getbeamer.com/js/beamer-embed.js'
      /> */}

      <Header title={'Planejamento'} icon showToday />
      {showModal == 'true' && reminders.length > 0 && (
        <ModalDefault
          width='50%'
          title={'Tarefas do dia'}
          handleShow={() => {
            localStorage.setItem('Q937p@qC', false)
            setShowModal(oldState => !oldState)
          }}
          content={
            <ReminderTable
              reminders={reminders}
              loading={loading}
              isDone={UpdateReminderDone}
            />
          }
        />
      )}

      {/* <Dashboard /> */}

      {/* <Divisor margin='5px' /> */}

      {/* <Header title={''} /> */}

      {/* <h1 className='page-header'></h1> */}

      <Divisor margin='5px' />

      <Row>
        <Col sm={12} lg={type === 'month' ? 12 : 4}>
          <CalendarSchedule
            renderDay={renderDay}
            onClickDay={onClickDay}
            onChangeMonth={onChangeMonth}
          />
        </Col>

        {loadingTravelers && <LoadingContainer />}

        <Col sm={12} lg={8}>
          {type === 'day' &&
            getTravelsByDay(
              entitys.filter(x => x.typeInfo == 'Travel'),
              day.format('YYYY-MM-DD'),
            ).length === 0 && (
              <>
                <SpaceBetweenDiv>
                  <h3>{getDateFull(day).full}</h3>

                  <CancelButton type='button' onClick={() => setType('month')}>
                    <i className='fas fa-times' />
                  </CancelButton>
                </SpaceBetweenDiv>
                <br></br>

                <DivFlexRight>
                  <LinkCreate
                    to={'/travels/create'}
                    state={{ start: day.format('YYYY-MM-DD') }}
                  >
                    Nova Viagem
                  </LinkCreate>
                </DivFlexRight>
                <div>
                  <p style={{ fontSize: '16px' }}>
                    Não há nenhuma viagem nesse dia.
                  </p>
                </div>
                <br></br>
              </>
            )}

          {type === 'day' &&
            getTravelsByDay(
              entitys.filter(x => x.typeInfo == 'Travel'),
              day.format('YYYY-MM-DD'),
            ).length > 0 && (
              <>
                <SpaceBetweenDiv>
                  <h3>{getDateFull(day).full}</h3>

                  <CancelButton type='button' onClick={() => setType('month')}>
                    <i className='fas fa-times' />
                  </CancelButton>
                </SpaceBetweenDiv>
                <Divisor />
                <SpaceBetweenDiv>
                  <h5>Viagens</h5>
                  <LinkCreate
                    to={'/travels/create'}
                    state={{ start: day.format('YYYY-MM-DD') }}
                  >
                    Nova Viagem
                  </LinkCreate>
                </SpaceBetweenDiv>
                {getTravelsByDay(
                  entitys.filter(x => x.typeInfo == 'Travel'),
                  day.format('YYYY-MM-DD'),
                ).map((e, i) => {
                  return <CardDetails travel={e} key={i} type={type} />
                })}
                <br></br>
              </>
            )}

          {type === 'day' &&
            getRemindersByDay(
              entitys.filter(x => x.typeInfo == 'Lembrete'),
              day.format('YYYY-MM-DD'),
            ).length === 0 && (
              <>
                <DivFlexRight>
                  <LinkCreate
                    to={'/reminders/create'}
                    state={{ shootingData: day.format('YYYY-MM-DD') }}
                  >
                    Nova Tarefa
                  </LinkCreate>
                </DivFlexRight>
                <div>
                  <p style={{ fontSize: '16px' }}>
                    Não há nenhuma tarefa nesse dia.
                  </p>
                </div>
              </>
            )}

          {type === 'day' &&
            getRemindersByDay(
              entitys.filter(x => x.typeInfo == 'Lembrete'),
              day.format('YYYY-MM-DD'),
            ).length > 0 && (
              <>
                <Divisor />
                <SpaceBetweenDiv>
                  <h5>Tarefas</h5>
                  <LinkCreate
                    to={'/reminders/create'}
                    state={{ shootingData: day.format('YYYY-MM-DD') }}
                  >
                    Nova Tarefa
                  </LinkCreate>
                </SpaceBetweenDiv>
                {getRemindersByDay(
                  entitys.filter(x => x.typeInfo == 'Lembrete'),
                  day.format('YYYY-MM-DD'),
                ).map((e, i) => {
                  return <CardDetails travel={e} key={i} type={type} />
                })}
              </>
            )}

          {type === 'day' &&
            getDepartureByDay(
              entitys.filter(x => x.typeInfo == 'Embarque'),
              day.format('YYYY-MM-DD'),
            )?.length === 0 && (
              <>
                <Divisor />
                <div>
                  <p style={{ fontSize: '16px' }}>
                    Não há nenhuma embarques nesse dia.
                  </p>
                </div>
              </>
            )}

          {type === 'day' &&
            getDepartureByDay(
              entitys.filter(x => x.typeInfo == 'Embarque'),
              day.format('YYYY-MM-DD'),
            )?.length > 0 && (
              <>
                <Divisor />
                <SpaceBetweenDiv>
                  <h5>Embarques</h5>
                </SpaceBetweenDiv>
                {getDepartureByDay(
                  entitys.filter(x => x.typeInfo == 'Embarque'),
                  day.format('YYYY-MM-DD'),
                ).map((e, i) => {
                  return <CardDetails travel={e} key={i} type={type} />
                })}
              </>
            )}
        </Col>

        {type === 'month' &&
          getTravelsByMonth(entitys, month.format('YYYY-MM-DD')).length === 0 &&
          !loadingTravelers && (
            <div>
              <p style={{ fontSize: '16px' }}>
                Não há nenhuma viagem nesse mês.
              </p>
            </div>
          )}

        {type === 'month' &&
          getTravelsByMonth(entitys, month.format('YYYY-MM-DD')).length > 0 &&
          !loadingTravelers && (
            <Row>
              {entitys?.filter(x => x.typeInfo == 'Embarque').length >= 1 && (
                <Col sm={12} lg={columns}>
                  <h3>Embarques</h3>

                  {getDepartureByMonth(
                    entitys.filter(x => x.typeInfo == 'Embarque'),
                    month.format('YYYY-MM-DD'),
                  ).map((e, i) => {
                    return <CardDetails travel={e} key={i} type={type} />
                  })}
                </Col>
              )}

              <Col sm={12} lg={columns}>
                <h3>Em Andamento</h3>

                {getActuallyTravels(
                  getTravelsByMonth(
                    entitys.filter(x => x.typeInfo == 'Travel'),
                    month.format('YYYY-MM-DD'),
                  ),
                ).map((e, i) => {
                  return <CardDetails travel={e} key={i} type={type} />
                })}
              </Col>

              <Col sm={12} lg={columns}>
                <h3>Próximas</h3>

                {getNextTravels(
                  getTravelsByMonth(
                    entitys.filter(x => x.typeInfo == 'Travel'),
                    month.format('YYYY-MM-DD'),
                  ),
                ).map((e, i) => {
                  return <CardDetails travel={e} key={i} type={type} />
                })}
              </Col>

              <Col sm={12} lg={columns}>
                <h3>Finalizadas</h3>

                {getPastTravels(
                  getTravelsByMonth(
                    entitys.filter(x => x.typeInfo == 'Travel'),
                    month.format('YYYY-MM-DD'),
                  ),
                ).map((e, i) => {
                  return <CardDetails travel={e} key={i} type={type} />
                })}
              </Col>
            </Row>
          )}
      </Row>
    </Container>
  )
}
