import { getDateFormated } from '~/functions/Date'
import {
  Container,
  Div,
  Image,
  TopTitle,
  DivEntity,
  EncapsuleBoxTitle,
  DivGlobal,
  AgencyImage,
  TotalValueLine,
  ImportantInformation,
  AgentInformation,
  DivEntityAbout,
  AboutTitle,
  AboutContainer,
  ImageAboutContainer,
  ImportantInformationAbout,
  ImportantInformationFooter,
  InfoBodyTd,
} from './styles'
import Footer from '../../Footer'
import GaleryIcon from '~/components/imgs/galery_icon.png'
import { FormatValueBRL, FormatValueUSD } from '~/functions/FormatValue'
import DivForHTML from '~/components/DivForHTML'
import { Hr } from '../Preview/styles'
import PreviewImages from '../Images'
import { Table as ReactstrapTable } from 'reactstrap'

export default function Download({
  fullWhite,
  withoutPadding,
  agency,
  title = '',
  image = '',
  image2 = '',
  description = '',
  primaryColor = '',
  allValues,
  dolar,
  fullValue,
  showTotalValue,
  id,
  createdAt,
  proposalFlights = [],
  proposalNauticals = [],
  proposalTrains = [],
  proposalHotels = [],
  proposalTickets = [],
  proposalVehicleRentals = [],
  proposalOtherServices = [],
  proposalActivitys = [],
  proposalDestinys = [],
  proposalTransfers = [],
  totalValue = null,
  agentName,
  agentEmail,
  isMobile,
  hideCover,
  agencyPhone,
}) {
  const formatterValue = value => {
    const valueToString = String(value)

    const includesDot =
      valueToString.includes('.') || valueToString.includes(',')

    const valueFormated = includesDot
      ? `R$ ${valueToString}`
      : `R$ ${valueToString}.00`

    return valueFormated
  }

  const dataProcessing = date => {
    const data = getDateFormated(date, 'DD/MM/YYYY HH:mm')

    if (data.includes('00:00')) {
      return getDateFormated(date, 'DD/MM/YYYY')
    } else {
      return getDateFormated(date, 'DD/MM/YYYY HH:mm')
    }
  }

  const changeNullForHyphen = value => {
    const transform = value == null ? '-' : value

    return transform
  }

  const changeNullForDate = value => {
    const transform = value == null ? '-' : dataProcessing(value)

    const result = transform == '01/01/0001' ? '-' : transform

    return result
  }

  return (
    <div>
      <DivGlobal fullWhite={fullWhite} withoutPadding={withoutPadding}>
        <Container style={{ color: '#6c6c6c' }} withoutPadding={withoutPadding}>
          <div className={hideCover ? '' : 'page'}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <AgencyImage
                src={`data:image/*;base64,${agency}`}
                alt='Logo da agência'
              />
              <div>
                <AgentInformation>N° da proposta: {id}</AgentInformation>
                <AgentInformation>Criada em: {createdAt}</AgentInformation>
                <AgentInformation>Consultor: {agentName}</AgentInformation>
                <AgentInformation>Email: {agentEmail}</AgentInformation>
                {agencyPhone && (
                  <AgentInformation>Telefone: {agencyPhone}</AgentInformation>
                )}
              </div>
            </div>
            <Div>
              <TopTitle primaryColor={primaryColor}>
                <p
                  style={{
                    fontFamily: 'Joanna Sans Nova Regular',
                    fontSize: '25px',
                    color: '#6c6c6c',
                  }}
                >
                  {title}
                </p>
                {/* <h6>
                Período da viagem: {dateInitial} a {dateFinal}
              </h6> */}
              </TopTitle>
            </Div>

            {image && image2 ? (
              <div
                style={{ display: 'flex', background: '#F8F8F8', gap: '0.5cm' }}
              >
                <Image
                  src={`data:image/*;base64,${image}`}
                  alt='Logo da agência'
                />
                <Image
                  src={`data:image/*;base64,${image2}`}
                  alt='Logo da agência'
                />
              </div>
            ) : image ? (
              <div
                style={{ display: 'flex', background: '#F8F8F8', gap: '0.5cm' }}
              >
                <Image
                  src={`data:image/*;base64,${image}`}
                  alt='Logo da agência'
                />
              </div>
            ) : (
              image2 && (
                <div style={{ display: 'flex', gap: '0.5cm' }}>
                  <Image
                    src={`data:image/*;base64,${image2}`}
                    alt='Logo da agência'
                  />
                </div>
              )
            )}
          </div>

          {description != '<p><br></p>' && (
            <DivEntityAbout style={{ background: '#F8F8F8' }}>
              <EncapsuleBoxTitle>
                <Hr style={{ flex: '1', borderTop: '1px solid #d9d9d9' }}></Hr>
                <AboutTitle>
                  <img
                    src={GaleryIcon}
                    style={{
                      width: '50px',
                      height: '50px',
                      objectFit: 'cover',
                      background: '#6c6c6c',
                    }}
                  />{' '}
                  Sobre a viagem
                </AboutTitle>
              </EncapsuleBoxTitle>
              <AboutContainer>
                <ImageAboutContainer>
                  <div style={{ display: 'flex', gap: '0.5cm' }}>
                    <Image
                      src={`data:image/*;base64,${image}`}
                      alt='Logo da agência'
                    />
                  </div>
                </ImageAboutContainer>
                <ImportantInformationAbout>
                  <DivForHTML
                    text={
                      `<a style="white-space: pre-line">` + description + `</a>`
                    }
                  />
                </ImportantInformationAbout>
              </AboutContainer>
            </DivEntityAbout>
          )}

          {proposalFlights?.some(x => x.proposalFlightExcerpts.length >= 1) && (
            <>
              <DivEntity primaryColor={primaryColor} className='no-break'>
                <EncapsuleBoxTitle>
                  <p>Opções de aéreo</p>
                </EncapsuleBoxTitle>
                {proposalFlights.map((flight, i) => (
                  <>
                    <div
                      key={`f-${i}`}
                      style={{
                        display: 'flex',
                        overflowX: `${isMobile ? 'scroll' : 'none'}`,
                      }}
                    >
                      <div style={{ flex: '1' }}>
                        <p style={{ fontSize: '16px', textAlign: 'start' }}>
                          Aéreo
                        </p>
                        <ReactstrapTable borderless striped>
                          <tbody>
                            <tr>
                              <InfoBodyTd>N°</InfoBodyTd>
                              <td>{i + 1}</td>
                            </tr>
                            <tr>
                              <InfoBodyTd>PASSAGEIROS</InfoBodyTd>
                              <td>{changeNullForHyphen(flight.passengers)}</td>
                            </tr>
                            <tr>
                              <InfoBodyTd>DESCRIÇÃO PASSAGEIROS</InfoBodyTd>
                              <td>adultos</td>
                            </tr>
                            <tr>
                              <InfoBodyTd>FORMA DE PAGAMENTO</InfoBodyTd>
                              <td>
                                {changeNullForHyphen(flight.descriptionPayment)}
                              </td>
                            </tr>
                            <tr>
                              <InfoBodyTd>BAGAGEM</InfoBodyTd>
                              <td>{changeNullForHyphen(flight.baggage)}</td>
                            </tr>
                            <tr>
                              <InfoBodyTd>VALOR</InfoBodyTd>
                              <td>{changeNullForHyphen(flight.value)}</td>
                            </tr>
                          </tbody>
                        </ReactstrapTable>
                      </div>
                      <div style={{ paddingLeft: '10px', flex: '2' }}>
                        <p style={{ fontSize: '16px', textAlign: 'start' }}>
                          Trechos do aéreo
                        </p>
                        <ReactstrapTable borderless striped>
                          <tbody>
                            <tr
                              style={{
                                fontWeight: '800',
                              }}
                            >
                              <InfoBodyTd>N°</InfoBodyTd>
                              <InfoBodyTd>CIA AEREA</InfoBodyTd>
                              <InfoBodyTd>ORIGEM</InfoBodyTd>
                              <InfoBodyTd>DESTINO</InfoBodyTd>
                              <InfoBodyTd>DATA SAÍDA</InfoBodyTd>
                              <InfoBodyTd>DATA CHEGADA</InfoBodyTd>
                            </tr>
                            {flight.proposalFlightExcerpts?.map(
                              (excerpt, i2) => (
                                // eslint-disable-next-line react/jsx-key
                                <tr>
                                  <td>{i2 + 1}</td>
                                  <td>{changeNullForHyphen(excerpt.cia)}</td>
                                  <td>{changeNullForHyphen(excerpt.origin)}</td>
                                  <td>
                                    {changeNullForHyphen(excerpt.destiny)}
                                  </td>
                                  <td>{changeNullForDate(excerpt.date)}</td>
                                  <td>
                                    {changeNullForDate(excerpt.dateArrival)}
                                  </td>
                                </tr>
                              ),
                            )}
                          </tbody>
                        </ReactstrapTable>
                      </div>
                    </div>
                    {flight?.proposalFlightFile?.length >= 1 && (
                      <PreviewImages files={flight?.proposalFlightFile} />
                    )}
                    <ImportantInformationFooter>
                      <b>Informações importantes do Aéreo {i + 1}:</b>{' '}
                      {flight.importantInformation}
                    </ImportantInformationFooter>
                  </>
                ))}
              </DivEntity>
            </>
          )}

          {proposalFlights?.some(x => x.proposalFlightExcerpts.length >= 1) && (
            <>
              <DivEntity primaryColor={primaryColor} className='no-break'>
                <EncapsuleBoxTitle>
                  <p>Opções de aéreo</p>
                </EncapsuleBoxTitle>
                {proposalFlights.map((flight, i) => (
                  <>
                    <div
                      key={`f-${i}`}
                      style={{
                        display: 'flex',
                        overflowX: `${isMobile ? 'scroll' : 'none'}`,
                      }}
                    >
                      <div style={{ flex: '1' }}>
                        <p style={{ fontSize: '16px', textAlign: 'start' }}>
                          Aéreo
                        </p>
                        <ReactstrapTable borderless striped>
                          <tbody>
                            <tr>
                              <InfoBodyTd>N°</InfoBodyTd>
                              <td>{i + 1}</td>
                            </tr>
                            <tr>
                              <InfoBodyTd>PASSAGEIROS</InfoBodyTd>
                              <td>{changeNullForHyphen(flight.passengers)}</td>
                            </tr>
                            <tr>
                              <InfoBodyTd>DESCRIÇÃO PASSAGEIROS</InfoBodyTd>
                              <td>adultos</td>
                            </tr>
                            <tr>
                              <InfoBodyTd>FORMA DE PAGAMENTO</InfoBodyTd>
                              <td>
                                {changeNullForHyphen(flight.descriptionPayment)}
                              </td>
                            </tr>
                            <tr>
                              <InfoBodyTd>BAGAGEM</InfoBodyTd>
                              <td>{changeNullForHyphen(flight.baggage)}</td>
                            </tr>
                            <tr>
                              <InfoBodyTd>VALOR</InfoBodyTd>
                              <td>{changeNullForHyphen(flight.value)}</td>
                            </tr>
                          </tbody>
                        </ReactstrapTable>
                      </div>
                      <div style={{ paddingLeft: '10px', flex: '2' }}>
                        <p style={{ fontSize: '16px', textAlign: 'start' }}>
                          Trechos do aéreo
                        </p>
                        <ReactstrapTable borderless striped>
                          <tbody>
                            <tr
                              style={{
                                fontWeight: '800',
                              }}
                            >
                              <InfoBodyTd>N°</InfoBodyTd>
                              <InfoBodyTd>CIA AEREA</InfoBodyTd>
                              <InfoBodyTd>ORIGEM</InfoBodyTd>
                              <InfoBodyTd>DESTINO</InfoBodyTd>
                              <InfoBodyTd>DATA SAÍDA</InfoBodyTd>
                              <InfoBodyTd>DATA CHEGADA</InfoBodyTd>
                            </tr>
                            {flight.proposalFlightExcerpts?.map(
                              (excerpt, i2) => (
                                // eslint-disable-next-line react/jsx-key
                                <tr>
                                  <td>{i2 + 1}</td>
                                  <td>{changeNullForHyphen(excerpt.cia)}</td>
                                  <td>{changeNullForHyphen(excerpt.origin)}</td>
                                  <td>
                                    {changeNullForHyphen(excerpt.destiny)}
                                  </td>
                                  <td>{changeNullForDate(excerpt.date)}</td>
                                  <td>
                                    {changeNullForDate(excerpt.dateArrival)}
                                  </td>
                                </tr>
                              ),
                            )}
                          </tbody>
                        </ReactstrapTable>
                      </div>
                    </div>
                    {flight?.proposalFlightFile?.length >= 1 && (
                      <PreviewImages files={flight?.proposalFlightFile} />
                    )}
                    <ImportantInformationFooter>
                      <b>Informações importantes do Aéreo {i + 1}:</b>{' '}
                      {flight.importantInformation}
                    </ImportantInformationFooter>
                  </>
                ))}
              </DivEntity>
            </>
          )}

          {proposalTrains.length >= 1 && (
            // eslint-disable-next-line react/jsx-key
            <DivEntity style={{ background: '#F8F8F8' }} className='no-break'>
              <EncapsuleBoxTitle>
                <p>Opções de trem</p>
              </EncapsuleBoxTitle>
              <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                <ReactstrapTable borderless striped>
                  <thead>
                    <tr
                      style={{
                        fontWeight: '800',
                      }}
                    >
                      <InfoBodyTd textCenter>N°</InfoBodyTd>
                      <InfoBodyTd textCenter>CIA</InfoBodyTd>
                      <InfoBodyTd textCenter>ORIGEM</InfoBodyTd>
                      <InfoBodyTd textCenter>DESTINO</InfoBodyTd>
                      <InfoBodyTd textCenter>PARTIDA</InfoBodyTd>
                      <InfoBodyTd textCenter>CHEGADA</InfoBodyTd>
                    </tr>
                  </thead>
                  <tbody>
                    {proposalTrains?.map((e, i) => (
                      // eslint-disable-next-line react/jsx-key
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{changeNullForHyphen(e.cia)}</td>
                        <td>{changeNullForHyphen(e.origin)}</td>
                        <td>{changeNullForHyphen(e.destiny)}</td>
                        <td>{changeNullForDate(e.departure)}</td>
                        <td>{changeNullForDate(e.arrival)}</td>
                        {allValues != false && dolar && (
                          <td>{FormatValueUSD(e.value)}</td>
                        )}
                        {allValues != false && !dolar && (
                          <td>{FormatValueBRL(e.value)}</td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </ReactstrapTable>
                {/* TODO: precisa ser criado campos para selecionar imagens */}

                <PreviewImages files={proposalTrains?.proposalFlightFile} />
                {proposalTrains.map((e, i) => (
                  <div key={i}>
                    {e.importantInformation && (
                      <ImportantInformationFooter>
                        <b>Informações importantes do trem {i + 1}: </b>
                        <a style={{ whiteSpace: 'pre-line' }}>
                          {e.importantInformation}
                        </a>
                      </ImportantInformationFooter>
                    )}
                  </div>
                ))}
              </div>
            </DivEntity>
          )}

          {proposalHotels.length >= 1 && (
            <DivEntity primaryColor={primaryColor} className='no-break'>
              <EncapsuleBoxTitle>
                <p>Opções de Hospedagem</p>
              </EncapsuleBoxTitle>
              <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                {proposalHotels.map((e, i) => (
                  <>
                    <ReactstrapTable borderless striped>
                      <thead>
                        <tr
                          styles={{
                            backgroundColor: primaryColor,
                            // textAlign: 'center',
                          }}
                        >
                          <InfoBodyTd textCenter>N°</InfoBodyTd>
                          <InfoBodyTd textCenter>NOME</InfoBodyTd>
                          <InfoBodyTd textCenter>ENDEREÇO</InfoBodyTd>
                          <InfoBodyTd textCenter>QUARTO</InfoBodyTd>
                          <InfoBodyTd textCenter>REGIME ALIMENTAR</InfoBodyTd>
                          <InfoBodyTd textCenter>CHECKIN</InfoBodyTd>
                          <InfoBodyTd textCenter>CHECKOUT</InfoBodyTd>
                          {allValues != false && (
                            <InfoBodyTd textCenter>VALOR</InfoBodyTd>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{changeNullForHyphen(e.name)}</td>
                          <td>{changeNullForHyphen(e.fullAddress)}</td>
                          <td>{changeNullForHyphen(e.roomType)}</td>
                          <td>{changeNullForHyphen(e.diet)}</td>
                          <td>{changeNullForDate(e.checkIn)}</td>
                          <td>{changeNullForDate(e.checkOut)}</td>
                          {allValues != false && dolar && (
                            <td>{FormatValueUSD(e.value)}</td>
                          )}
                          {allValues != false && !dolar && (
                            <td>{FormatValueBRL(e.value)}</td>
                          )}
                        </tr>
                      </tbody>
                    </ReactstrapTable>
                    <PreviewImages files={e?.proposalHotelFile} />
                    <div>
                      {e.importantInformation && (
                        <ImportantInformationFooter>
                          <b>Informações importantes do Hotel {i + 1}: </b>
                          <a style={{ whiteSpace: 'pre-line' }}>
                            {e.importantInformation}
                          </a>
                        </ImportantInformationFooter>
                      )}
                    </div>
                  </>
                ))}
              </div>
            </DivEntity>
          )}

          {proposalFlights?.some(x => x.proposalFlightExcerpts.length >= 1) && (
            <>
              <DivEntity primaryColor={primaryColor} className='no-break'>
                <EncapsuleBoxTitle>
                  <p>Itinerário terrestre</p>
                </EncapsuleBoxTitle>
                {proposalFlights.map((flight, i) => (
                  <>
                    <div
                      key={`f-${i}`}
                      style={{
                        display: 'flex',
                        overflowX: `${isMobile ? 'scroll' : 'none'}`,
                      }}
                    >
                      <div style={{ flex: '1' }}>
                        <p style={{ fontSize: '16px', textAlign: 'start' }}>
                          Aéreo
                        </p>
                        <ReactstrapTable borderless striped>
                          <tbody>
                            <tr>
                              <InfoBodyTd>N°</InfoBodyTd>
                              <td>{i + 1}</td>
                            </tr>
                            <tr>
                              <InfoBodyTd>PASSAGEIROS</InfoBodyTd>
                              <td>{changeNullForHyphen(flight.passengers)}</td>
                            </tr>
                            <tr>
                              <InfoBodyTd>DESCRIÇÃO PASSAGEIROS</InfoBodyTd>
                              <td>adultos</td>
                            </tr>
                            <tr>
                              <InfoBodyTd>FORMA DE PAGAMENTO</InfoBodyTd>
                              <td>
                                {changeNullForHyphen(flight.descriptionPayment)}
                              </td>
                            </tr>
                            <tr>
                              <InfoBodyTd>BAGAGEM</InfoBodyTd>
                              <td>{changeNullForHyphen(flight.baggage)}</td>
                            </tr>
                            <tr>
                              <InfoBodyTd>VALOR</InfoBodyTd>
                              <td>{changeNullForHyphen(flight.value)}</td>
                            </tr>
                          </tbody>
                        </ReactstrapTable>
                      </div>
                      <div style={{ paddingLeft: '10px', flex: '2' }}>
                        <p style={{ fontSize: '16px', textAlign: 'start' }}>
                          Trechos do aéreo
                        </p>
                        <ReactstrapTable borderless striped>
                          <tbody>
                            <tr
                              style={{
                                fontWeight: '800',
                              }}
                            >
                              <InfoBodyTd>N°</InfoBodyTd>
                              <InfoBodyTd>CIA AEREA</InfoBodyTd>
                              <InfoBodyTd>ORIGEM</InfoBodyTd>
                              <InfoBodyTd>DESTINO</InfoBodyTd>
                              <InfoBodyTd>DATA SAÍDA</InfoBodyTd>
                              <InfoBodyTd>DATA CHEGADA</InfoBodyTd>
                            </tr>
                            {flight.proposalFlightExcerpts?.map(
                              (excerpt, i2) => (
                                // eslint-disable-next-line react/jsx-key
                                <tr>
                                  <td>{i2 + 1}</td>
                                  <td>{changeNullForHyphen(excerpt.cia)}</td>
                                  <td>{changeNullForHyphen(excerpt.origin)}</td>
                                  <td>
                                    {changeNullForHyphen(excerpt.destiny)}
                                  </td>
                                  <td>{changeNullForDate(excerpt.date)}</td>
                                  <td>
                                    {changeNullForDate(excerpt.dateArrival)}
                                  </td>
                                </tr>
                              ),
                            )}
                          </tbody>
                        </ReactstrapTable>
                      </div>
                    </div>
                    {flight?.proposalFlightFile?.length >= 1 && (
                      <PreviewImages files={flight?.proposalFlightFile} />
                    )}
                    <ImportantInformationFooter>
                      <b>Informações importantes do Aéreo {i + 1}:</b>{' '}
                      {flight.importantInformation}
                    </ImportantInformationFooter>
                  </>
                ))}
              </DivEntity>
            </>
          )}

          {proposalNauticals.length >= 1 && (
            // eslint-disable-next-line react/jsx-key
            <DivEntity primaryColor={primaryColor} className='no-break'>
              <EncapsuleBoxTitle>
                <p>Náutico</p>
              </EncapsuleBoxTitle>
              <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                {proposalNauticals.map((e, i) => (
                  <>
                    <ReactstrapTable borderless striped>
                      <tbody>
                        {i == 0 && (
                          <tr>
                            <InfoBodyTd textCenter>N°</InfoBodyTd>
                            <InfoBodyTd textCenter>CIA</InfoBodyTd>
                            <InfoBodyTd textCenter>TIPO</InfoBodyTd>
                            <InfoBodyTd textCenter>CABINE</InfoBodyTd>
                            <InfoBodyTd textCenter>ORIGEM</InfoBodyTd>
                            <InfoBodyTd textCenter>DESTINO</InfoBodyTd>
                            <InfoBodyTd textCenter>PARTIDA</InfoBodyTd>
                            <InfoBodyTd textCenter>CHEGADA</InfoBodyTd>
                            {allValues != false && <th>VALOR</th>}
                          </tr>
                        )}

                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{changeNullForHyphen(e.cia)}</td>
                          <td>{changeNullForHyphen(e.type)}</td>
                          <td>{changeNullForHyphen(e.cabinType)}</td>
                          <td>{changeNullForHyphen(e.origin)}</td>
                          <td>{changeNullForHyphen(e.destiny)}</td>
                          <td>{changeNullForDate(e.departure)}</td>
                          <td>{changeNullForDate(e.arrival)}</td>
                          {allValues != false && dolar && (
                            <td>{FormatValueUSD(e.value)}</td>
                          )}
                          {allValues != false && !dolar && (
                            <td>{FormatValueBRL(e.value)}</td>
                          )}
                        </tr>
                      </tbody>
                    </ReactstrapTable>
                    <PreviewImages files={e?.proposalNauticalFiles} />
                    <ImportantInformationFooter>
                      <b>Informações importantes do náutico {i + 1}: </b>
                      <a style={{ whiteSpace: 'pre-line' }}>
                        {e.importantInformation}
                      </a>
                    </ImportantInformationFooter>
                  </>
                ))}
              </div>
            </DivEntity>
          )}

          {proposalVehicleRentals.length >= 1 && (
            <DivEntity primaryColor={primaryColor} className='no-break'>
              <EncapsuleBoxTitle>
                <p>Locações veiculares</p>
              </EncapsuleBoxTitle>
              <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                <ReactstrapTable borderless striped>
                  <tbody>
                    <tr>
                      <InfoBodyTd textCenter>N°</InfoBodyTd>
                      <InfoBodyTd textCenter>Locadora</InfoBodyTd>
                      <InfoBodyTd textCenter>Tipo</InfoBodyTd>
                      <InfoBodyTd textCenter>Endereço de locação</InfoBodyTd>
                      <InfoBodyTd textCenter>Endereço de devolução</InfoBodyTd>
                      {/* <InfoBodyTd>DATA DA LOCAÇÃO</InfoBodyTd>
                    <InfoBodyTd>DATA DA DEVOLUÇÃO</InfoBodyTd> */}
                      {allValues != false && (
                        <InfoBodyTd textCenter>VALOR</InfoBodyTd>
                      )}
                    </tr>
                    {proposalVehicleRentals.map((e, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{changeNullForHyphen(e.provider)}</td>
                        <td>{changeNullForHyphen(e.type)}</td>
                        <td>{changeNullForHyphen(e.originAddress)}</td>
                        <td>{changeNullForHyphen(e.destinyAddress)}</td>
                        {/* <td>{changeNullForDate(e.checkIn)}</td>
                      <td>{changeNullForDate(e.checkOut)}</td> */}
                        {allValues != false && dolar && (
                          <td>{FormatValueUSD(e.value)}</td>
                        )}
                        {allValues != false && !dolar && (
                          <td>{FormatValueBRL(e.value)}</td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </ReactstrapTable>
                <br></br>
                {proposalVehicleRentals.map((e, i) => (
                  <div key={i}>
                    {e.importantInformation && (
                      <ImportantInformationFooter>
                        <b>
                          Informações importantes da locação veicular {i + 1}:{' '}
                        </b>
                        <a
                          style={{
                            whiteSpace: 'pre-line',
                          }}
                        >
                          {e.importantInformation}
                        </a>
                      </ImportantInformationFooter>
                    )}
                    <br />
                  </div>
                ))}
              </div>
            </DivEntity>
          )}

          {proposalTickets.length >= 1 && (
            <DivEntity style={{ background: '#F8F8F8' }} className='no-break'>
              <EncapsuleBoxTitle>
                <p>Ingresso</p>
              </EncapsuleBoxTitle>
              <br></br>
              <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                <ReactstrapTable borderless striped>
                  <tbody>
                    <tr>
                      <InfoBodyTd textCenter>N°</InfoBodyTd>
                      <InfoBodyTd textCenter>Título</InfoBodyTd>
                      <InfoBodyTd textCenter>Início</InfoBodyTd>
                      <InfoBodyTd textCenter>Fim</InfoBodyTd>
                      {allValues != false && (
                        <InfoBodyTd textCenter>Valor</InfoBodyTd>
                      )}
                    </tr>
                    {proposalTickets.map((e, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{changeNullForHyphen(e.title)}</td>
                        <td>{changeNullForDate(e.start)}</td>
                        <td>{changeNullForDate(e.end)}</td>
                        {allValues != false && dolar && (
                          <td>{FormatValueUSD(e.value)}</td>
                        )}
                        {allValues != false && !dolar && (
                          <td>{FormatValueBRL(e.value)}</td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </ReactstrapTable>
                <PreviewImages files={proposalTrains?.proposalFlightFile} />

                {proposalTickets.map((e, i) => (
                  <div key={i}>
                    {e.desc && (
                      <ImportantInformation>
                        <b>Descrição do ticket {i + 1}: </b>
                        <a
                          style={{
                            fontSize: '16px',
                            whiteSpace: 'pre-line',
                          }}
                        >
                          {e.desc}
                        </a>
                      </ImportantInformation>
                    )}
                    <br />
                  </div>
                ))}
              </div>
            </DivEntity>
          )}

          {proposalOtherServices.length >= 1 && (
            <DivEntity style={{ background: '#F8F8F8' }} className='no-break'>
              <EncapsuleBoxTitle>
                <p>Outros serviços</p>
              </EncapsuleBoxTitle>
              <br></br>
              <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                <ReactstrapTable borderless striped>
                  <tbody>
                    <tr>
                      <InfoBodyTd textCenter>N°</InfoBodyTd>
                      <InfoBodyTd textCenter>Título</InfoBodyTd>
                      <InfoBodyTd textCenter>Endereço</InfoBodyTd>
                      <InfoBodyTd textCenter>Cidade</InfoBodyTd>
                      <InfoBodyTd textCenter>Início</InfoBodyTd>
                      <InfoBodyTd textCenter>Fim</InfoBodyTd>
                      {allValues != false && (
                        <InfoBodyTd textCenter>Valor</InfoBodyTd>
                      )}
                    </tr>
                    {proposalOtherServices.map((e, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{changeNullForHyphen(e.title)}</td>
                        <td>{changeNullForDate(e.start)}</td>
                        <td>{changeNullForDate(e.city)}</td>
                        <td>{changeNullForDate(e.endAddress)}</td>
                        <td>{changeNullForDate(e.end)}</td>
                        {allValues != false && dolar && (
                          <td>{FormatValueUSD(e.value)}</td>
                        )}
                        {allValues != false && !dolar && (
                          <td>{FormatValueBRL(e.value)}</td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </ReactstrapTable>
                {/* TODO mockado depois tirar */}
                <PreviewImages files={proposalTrains?.proposalFlightFile} />

                {proposalOtherServices.map((e, i) => (
                  <div key={i}>
                    {e.desc && (
                      <ImportantInformation>
                        <b>
                          Informações importantes de outros serviços {i + 1}:{' '}
                        </b>
                        <a
                          style={{
                            fontSize: '16px',
                            whiteSpace: 'pre-line',
                          }}
                        >
                          {e.desc}
                        </a>
                      </ImportantInformation>
                    )}
                    <br />
                  </div>
                ))}
              </div>
            </DivEntity>
          )}

          {proposalDestinys.length >= 1 && (
            <DivEntity className='no-break'>
              <EncapsuleBoxTitle>
                <p>Destinos</p>
              </EncapsuleBoxTitle>
              <br></br>
              <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                <ReactstrapTable borderless striped>
                  <tbody>
                    <tr>
                      <InfoBodyTd textCenter>N°</InfoBodyTd>
                      <InfoBodyTd textCenter>Nome</InfoBodyTd>
                      <InfoBodyTd textCenter>Início</InfoBodyTd>
                      <InfoBodyTd textCenter>Fim</InfoBodyTd>
                      {allValues != false && (
                        <InfoBodyTd textCenter>Valor</InfoBodyTd>
                      )}
                    </tr>
                    {proposalDestinys.map((e, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{changeNullForHyphen(e.name)}</td>
                        <td>{changeNullForDate(e.dateStart)}</td>
                        <td>{changeNullForDate(e.dateEnd)}</td>
                      </tr>
                    ))}
                  </tbody>
                </ReactstrapTable>
                {/* TODO mockado depois tirar */}
                <PreviewImages files={proposalTrains?.proposalFlightFile} />
              </div>
            </DivEntity>
          )}

          {proposalTransfers.length >= 1 && (
            <DivEntity style={{ background: '#F8F8F8' }} className='no-break'>
              <EncapsuleBoxTitle>
                <p>Transfers</p>
              </EncapsuleBoxTitle>
              <br></br>
              <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                <ReactstrapTable borderless striped>
                  <tbody>
                    <tr>
                      <InfoBodyTd textCenter>N°</InfoBodyTd>
                      <InfoBodyTd textCenter>Origem</InfoBodyTd>
                      <InfoBodyTd textCenter>Endereço de origem</InfoBodyTd>
                      <InfoBodyTd textCenter>Destino</InfoBodyTd>
                      <InfoBodyTd textCenter>Endereço de destino</InfoBodyTd>
                      <InfoBodyTd textCenter>Checkin</InfoBodyTd>
                      <InfoBodyTd textCenter>Chekout</InfoBodyTd>
                      {allValues != false && (
                        <InfoBodyTd textCenter>Valor</InfoBodyTd>
                      )}
                    </tr>
                    {proposalTransfers.map((e, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{changeNullForHyphen(e.origin)}</td>
                        <td>{changeNullForDate(e.originAddress)}</td>
                        <td>{changeNullForDate(e.dateEnd)}</td>
                      </tr>
                    ))}
                  </tbody>
                </ReactstrapTable>
                {/* TODO mockado depois tirar */}
                <PreviewImages files={proposalTrains?.proposalFlightFile} />
                {proposalTransfers.map((e, i) => (
                  <div key={i}>
                    {e.importantInformation && (
                      <ImportantInformationFooter>
                        <b>Informações importantes do transfer {i + 1}: </b>
                        <a
                          style={{
                            whiteSpace: 'pre-line',
                          }}
                        >
                          {e.importantInformation}
                        </a>
                      </ImportantInformationFooter>
                    )}
                    <br />
                  </div>
                ))}
              </div>
            </DivEntity>
          )}

          {proposalActivitys.length >= 1 && (
            <DivEntity style={{ background: '#F8F8F8' }} className='no-break'>
              <EncapsuleBoxTitle>
                <p>Atividades do roteiro</p>
              </EncapsuleBoxTitle>
              <ReactstrapTable>
                <tbody>
                  <tr>
                    <InfoBodyTd textCenter>N°</InfoBodyTd>
                    <InfoBodyTd textCenter>Nome</InfoBodyTd>
                    <InfoBodyTd textCenter>Cidade</InfoBodyTd>
                    <InfoBodyTd textCenter>Inicio</InfoBodyTd>
                    <InfoBodyTd textCenter>Fim</InfoBodyTd>
                    {allValues != false && (
                      <InfoBodyTd textCenter>Valor</InfoBodyTd>
                    )}
                  </tr>
                  {proposalActivitys.map((e, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{changeNullForHyphen(e.name)}</td>
                      <td>{changeNullForDate(e.city)}</td>
                      <td>{changeNullForDate(e.dateStart)}</td>
                      <td>{changeNullForDate(e.dateEnd)}</td>
                    </tr>
                  ))}
                </tbody>
              </ReactstrapTable>
            </DivEntity>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              borderBottom: '1px solid #6c6c6c33',
              padding: '20px 20px',
            }}
          >
            <p>
              <b>TOTAL</b>
            </p>
            <p>R$ {formatterValue(totalValue)}</p>
          </div>
        </Container>
        <Footer image={agency} />
      </DivGlobal>
    </div>
  )
}
