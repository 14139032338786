export const SET_CONVERSATIONS = 'SET_CONVERSATIONS'
export const CLEAR_CONVERSATIONS = 'CLEAR_CONVERSATIONS'
export const CLEAR_CONVERSATIONS_STATE = 'CLEAR_CONVERSATIONS_STATE'
export const APPLY_CONVERSATIONS_CHANGES = 'APPLY_CONVERSATIONS_CHANGES'

export const setConversations = (data, dispatch) => {
  dispatch({
    data: data,
    type: SET_CONVERSATIONS,
  })
}

export const clearConversationsAction = dispatch => {
  dispatch({
    type: CLEAR_CONVERSATIONS,
  })
}

export const clearConversationsState = dispatch => {
  dispatch({
    data: null,
    type: CLEAR_CONVERSATIONS_STATE,
  })
}

export const applyConversationsChanges = (data, dispatch) => {
  dispatch({
    data: data,
    type: APPLY_CONVERSATIONS_CHANGES,
  })
}
