import ContactListSkeleton from './ContactList/skeleton'
import ContactsFiltersSkeleton from './Filters/skeleton'
import * as S from './styles'

export default function ContactsSkeleton() {
  return (
    <S.ContactsSection>
      <ContactsFiltersSkeleton />

      <ContactListSkeleton />
    </S.ContactsSection>
  )
}
