import * as S from './styles'
import SkeletonLine from '~/components/Skeleton'

export default function ChatHeaderSkeleton() {
  return (
    <S.ChatHeader>
      <S.ChatHeaderTitle>
        <S.AvatarIcon className='fas fa-user-circle' />

        <SkeletonLine width={128} height={35} />

        <SkeletonLine width={38} height={35} />
      </S.ChatHeaderTitle>

      <S.ActionButtons>
        <SkeletonLine width={64} height={35} />

        <SkeletonLine width={64} height={35} />
      </S.ActionButtons>
    </S.ChatHeader>
  )
}
