import { useState } from 'react'
import { Button, Spinner } from 'reactstrap'
import * as S from '../styles'

export default function ChatErrorFallbackScreen() {
  const [loading, setLoading] = useState(false)

  return (
    <S.ContainerError>
      <i className='fas fa-exclamation-triangle'></i>

      <p>Ooops...! Um erro ocorreu.</p>

      <Button
        onClick={() => {
          setLoading(true)

          window.location.reload()
        }}
        size='md'
        disabled={loading}
      >
        {!loading && (
          <span>
            Atualizar página <i className='fas fa-redo'></i>
          </span>
        )}

        {loading && <Spinner color='#ccc' size={'sm'} />}
      </Button>
    </S.ContainerError>
  )
}
