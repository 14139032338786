import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const CardFormat = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  padding: 6px;
  border-radius: 6px;
  background-color: ${props => props.backgroundColor ?? '#cccccc'};

  &:hover {
    background-color: ${props => props.backgroundColor ?? '#cccccc'}80;
  }
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: bold;
  .title {
    font-size: 16px;
    color: ${props => props.theme.colors.primary};
  }
`

export const LinkDetails = styled(Link)`
  display: flex;
  padding: 0px 12px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #333;
`
