import { Button } from 'reactstrap'
import styled from 'styled-components'

export const ChatHeader = styled.header`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-bottom: 1px solid #ccc;
`

export const ChatHeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`

export const AvatarIcon = styled.i`
  font-size: 32px;
  color: #bdbfbf;
`

export const CurrentContactName = styled.span`
  font-weight: 600;
  font-size: 16px;
`

export const WhatsAppIcon = styled.i`
  font-size: 24px;
  color: #00d757;
`

export const EndButton = styled(Button)`
  background: ${props => props.theme.colors.primary};

  :hover {
    background: ${props => props.theme.colors.primary};
  }
`

export const ActionButtons = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`
