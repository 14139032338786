import * as S from './styles'
import ChatHeaderSkeleton from './Header/skeleton'
import ChatConversationSkeleton from './ChatConversation/skeleton'
import SkeletonLine from '~/components/Skeleton'

export default function ChatSkeleton() {
  return (
    <S.Container>
      <ChatHeaderSkeleton />

      <S.MessagesContainer>
        <ChatConversationSkeleton />
      </S.MessagesContainer>

      <S.Keyboard>
        <S.Footer>
          <S.Features>
            <SkeletonLine width={19} height={19} />

            <SkeletonLine width={19} height={19} />

            <SkeletonLine width={19} height={19} />

            <SkeletonLine width={19} height={19} />
          </S.Features>

          <SkeletonLine width={64} height={38} />
        </S.Footer>
      </S.Keyboard>
    </S.Container>
  )
}
