import ContactsSkeleton from './Contacts/skeleton'
import ConversationSkeleton from './Conversation/skeleton'
import * as S from './styles'

export default function ChatCenterSkeleton() {
  return (
    <S.Container>
      <ContactsSkeleton />

      <ConversationSkeleton />
    </S.Container>
  )
}
