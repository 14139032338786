import { Badge } from 'reactstrap'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  min-height: 90px;
  font-size: 14px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 0;
  padding: 8px;
  border-bottom: 1px solid #ccc;
  cursor: ${props => (props.active || !props.onClick ? 'default' : 'pointer')};
  background: ${props => (props.active ? '#eaeaea' : 'transparent')};
  filter: ${props =>
    props.isFinalized ? 'grayscale(1) opacity(0.6)' : 'grayscale(0)'};
`

export const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;
`

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 4px;
  align-items: center;
  flex-wrap: wrap;
`

export const TagBadge = styled(Badge)`
  background-color: ${props => props.theme.colors.primary} !important;
  font-size: 12px;
  white-space: break-spaces;
  word-break: break-all;
`

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-top: 8px;
`

export const BodyContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
`

export const BodyInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-wrap: nowrap;
  gap: 8px;
`

export const Details = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
`

export const DetailsUsername = styled.div`
  width: 100%;
  color: #888;
  font-size: 12px;
`

export const DetailsText = styled.div`
  width: 100%;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  max-height: 1.4em;
  line-height: 1.4em;
`

export const AgentName = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  color: #888;
`

export const Avatar = styled.div`
  color: #ccc;
  font-size: 38px;
`

export const Name = styled.div`
  font-size: 16px;
  color: #333;
`

export const RatingContainer = styled.div`
  text-wrap: nowrap;

  @media screen and (max-width: 1280px) {
    left: 192px;
  }

  .p-rating-icon {
    font-size: 1em;
  }
`

export const Time = styled.b`
  font-size: 12px;
  color: #888;
`

export const NewMessageBadge = styled.span`
  width: max-content;
  background-color: #198754;
  width: 10px;
  height: 10px;
  border-radius: 100%;
`
