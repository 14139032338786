import { combineReducers } from 'redux'
import auth from './auth'
import page from './page'
import conversationReducer from '~/store/reducers/conversationReducer'
import contactReducer from '~/store/reducers/contactReducer'
import serviceReducer from '~/store/reducers/serviceReducer'
import customerReducer from '~/store/reducers/customerReducer'
import agentReducer from '~/store/reducers/agentReducer'

const slicers = combineReducers({
  auth: auth,
  page: page,
  conversationReducer: conversationReducer,
  contactReducer: contactReducer,
  serviceReducer: serviceReducer,
  customerReducer: customerReducer,
  agentReducer: agentReducer,
})

export default slicers
