import styled from 'styled-components'

export const Container = styled.div`
  width: 1080px;
  min-width: 1080px;
  max-width: 1600px;
  height: 100%;
  background: #ffffff;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: auto;
`
