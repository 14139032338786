import * as S from './styles'
import SkeletonLine from '~/components/Skeleton'

export default function ContactConversationSkeleton() {
  return (
    <S.Container>
      <i
        style={{
          fontSize: '68px',
          width: '68px',
          height: '68px',
          color: '#ccc',
        }}
        className='fas fa-user-circle'
      />

      <S.Main>
        <S.Title>
          <S.Name>
            <SkeletonLine width={128} height={19} />
          </S.Name>

          <S.RatingContainer>
            <SkeletonLine width={64} height={19} />
          </S.RatingContainer>
        </S.Title>

        <S.TagContainer>
          <SkeletonLine width={64} height={19} />

          <SkeletonLine width={64} height={19} />

          <SkeletonLine width={64} height={19} />
        </S.TagContainer>

        <S.Body>
          <S.BodyContent>
            <S.AgentName>
              <SkeletonLine width={19} height={19} />

              <SkeletonLine width={128} height={19} />
            </S.AgentName>
          </S.BodyContent>

          <S.BodyInfo>
            <SkeletonLine width={19} height={19} />
          </S.BodyInfo>
        </S.Body>
      </S.Main>
    </S.Container>
  )
}
