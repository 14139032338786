import * as S from './styles'
import SkeletonLine from '~/components/Skeleton'

export default function InformationHeaderSkeleton() {
  return (
    <S.Container>
      <i
        style={{ fontSize: '120px', color: '#bdbfbf' }}
        className='fas fa-user-circle'
      />

      <SkeletonLine width={200} height={19} />

      <SkeletonLine width={200} height={19} />

      <SkeletonLine width={64} height={19} />

      <SkeletonLine width={19} height={19} />
    </S.Container>
  )
}
