import ChatSkeleton from './Chat/skeleton'
import InformationSkeleton from './Information/skeleton'
import * as S from './styles'

export default function ConversationSkeleton() {
  return (
    <>
      <S.SectionChat>
        <ChatSkeleton />
      </S.SectionChat>

      <S.SectionInfo>
        <InformationSkeleton />
      </S.SectionInfo>
    </>
  )
}
