import styled from 'styled-components'

export const Container = styled.div``

export const Label = styled.label`
  margin: 2px 4px;
  font-weight: 600;
`

export const Input = styled.div`
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`
